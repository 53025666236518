import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import './i18n'; // Importer le fichier de configuration d'i18next

import { MyThemeProvider } from "./context/theme-context";
import { ApiCoinGeckoProvider } from "./context/apiCoinGecko-context";
import { SettingsProvider } from "./context/settings-context";
import { RealTokensProvider } from "./context/realTokens-context";
import { SnackbarProvider } from "./context/customsnackbar-context";
import { Web3Provider } from "./context/web3-context.jsx";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
	<React.StrictMode>
		<SnackbarProvider>
			<SettingsProvider>
				<MyThemeProvider>
					<RealTokensProvider>
						<ApiCoinGeckoProvider>
							<Web3Provider>
								<App />
							</Web3Provider>
						</ApiCoinGeckoProvider>
					</RealTokensProvider>
				</MyThemeProvider>
			</SettingsProvider>
		</SnackbarProvider>
	</React.StrictMode>
);
