import React, { useContext, useEffect, useRef, useState } from "react";
import { SettingsContext } from "../../../context/settings-context";
import { useTheme } from "@mui/system";

import Header from "../../../components/Header/Header";
import LoadingWebSite from "../../../components/Common/LoadingWebSite";

import { blue, grey } from "@mui/material/colors";
import { Avatar, Box, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import AnalyticsTwoToneIcon from "@mui/icons-material/AnalyticsTwoTone";
import InsightsIcon from "@mui/icons-material/Insights";

function MtPelerin() {
	const { settingsLoaded } = useContext(SettingsContext);
	const theme = useTheme();

	const referalURL_realT = "https://realt.co/ref/Frederic%20Pitot/";
	const referalURL_BienPreter = "https://www.bienpreter.com/parrain/U61F6DDA125AA3";
	const referalURL_MtPelerin = "https://www.mtpelerin.com/buy-xdai?bsc=EUR&sdc=EUR&rfr=CvZZWr53";

	const RealTLogo = theme.palette.mode === "dark" ? "/images/RealT_Logo_dark.svg" : "/images/RealT_Logo.svg";
	const BienPreterLogo = theme.palette.mode === "dark" ? "/images/bienpreter_dark.png" : "/images/bienpreter.png";
	const MtPelerinLogo = theme.palette.mode === "dark" ? "/images/mtpelerin.svg" : "/images/mtpelerin.svg";
	const CommunityLogo = theme.palette.mode === "dark" ? "/images/realtcommunity_dark.png" : "/images/realtcommunity.png";
	const RentTrackerLogo = theme.palette.mode === "dark" ? "/images/renttraker_dark.png" : "/images/renttraker.png";

	const handleReferalURLClick = (url) => {
		window.open(url, "_blank");
	};

	return (
		<>
			{!settingsLoaded && <LoadingWebSite />}
			{settingsLoaded && (
				<Box sx={{ m: 0, display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
					<IconButton onClick={() => handleReferalURLClick(referalURL_MtPelerin)}>
						<Avatar
							alt={"MtPelerinLogo"}
							src={MtPelerinLogo}
							fontSize='small'
							sx={{ width: 200, height: 80 }}
							imgProps={{ style: { objectFit: "contain", height: "65%", width: "auto" } }}
						/>
					</IconButton>
					<Typography variant='h5' sx={{ fontWeight: "bold", mb: 1, color: "primary" }}>
						Buy XDAI the easy way !
					</Typography>
				</Box>
			)}
		</>
	);
}

export default MtPelerin;
