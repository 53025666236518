import React, { useContext } from "react";
// import axios from "axios";
import { SettingsContext } from "../../context/settings-context";

import { Box } from "@mui/material";

import BarPropertyYield from "./RealTokensChart/BarPropertyYield";
import HorizontalCollapsibleTokenList from "./RealTokensChart/HorizontalCollapsibleTokenList";
import PiePropertyDiversity from "./RealTokensChart/PiePropertyDiversity";
import PiePropertyType from "./RealTokensChart/PiePropertyType";
import PieYearHolderCost from "./RealTokensChart/PieYearHolderCost";
import TableInvestment from "./RealTokensChart/TableInvestment";
import TableMonthHolderCost from "./RealTokensChart/TableMonthHolderCost";
import TableProperties from "./RealTokensChart/TableProperties";
import TablePropertyPrice from "./RealTokensChart/TablePropertyPrice";
import TableTokens from "./RealTokensChart/TableTokens";
import TableValuationAssets from "./RealTokensChart/TableValuationAssets";
import TableYearHolderCost from "./RealTokensChart/TableYearHolderCost";
import Menu01 from "./RealTokensChart/Menu01";
import Menu02 from "./RealTokensChart/Menu02";
import TableTokenValuation from "./RealTokensChart/TableTokenValuation";
import Header from "./RealTokensChart/Header";
import Menu03 from "./RealTokensChart/Menu03";
import TableLevinswap from "./RealTokensChart/TableLevinswap";
import ChartMonthInvestment from "./RealTokensChart/ChartMonthInvestment";

function RealTokens() {
	const { realTokens, holderWallets } = useContext(SettingsContext);

	if (!realTokens) return <></>;

	// console.log("holderWallets",holderWallets)

	const returnValue = (
		<Box id={"createPDF"} display='flex' flexDirection='column' alignItems='center' justifyContent='center' sx={{ width: 1, p: 2 }}>
			<Header />
			{realTokens && (
				<Box>
					<Menu01
						number='1'
						title='RealT Investments'
						description={
							<>
								Overview of investments with RealT, featuring detailed tables and key metrics to understand the performance of the entire real estate
								portfolio at a glance. Assets Valuations are calculated since <strong>May 2023</strong>, historic of assets need to be completed!
							</>
						}
						content={
							<>
								<TableInvestment />
								<TableProperties />
								{/* <TableTokens /> */}
								<TableValuationAssets />
								<Menu02
									number='1.1'
									title='Last 6 months Marketplace Activity'
									description={
										<>
											Delve into detailed marketplace activity over the past six months. Explore an interactive timeline showcasing each property's
											launch and transactions. <br />
											Click to expand monthly summaries and view specific properties sold, gaining valuable insights into market trends and investment
											opportunities.
										</>
									}
									content={
										<>
											<Box sx={{ display: "flex", flexDirection:'column',alignItems: "center", justifyContent: "center", mb: 2 }}>
												{/* <ChartMonthInvestment /> */}
												<HorizontalCollapsibleTokenList />
											</Box>
										</>
									}
								/>
								<Menu02
									number='1.2'
									title='Detailed Holder Costs'
									description='Explore the specifics of annual and monthly holder costs with comprehensive charts and tables. Get insights into property management fees, maintenance costs, and more to understand the financial nuances of your RealT investments.'
									content={
										<>
											<PieYearHolderCost />
											<TableYearHolderCost />
											{/* <TableMonthHolderCost /> */}
										</>
									}
								/>
								<Menu02
									number='1.3'
									title='RealT Investments by Property Type '
									description={
										<>
											Overview of investments with RealT, featuring detailed tables and key metrics to understand the performance by{" "}
											<strong>Property Type</strong> of the entire real estate portfolio at a glance. <br />
											Assets Valuations are calculated since <strong>May 2023</strong>, historic of assets need to be completed!
										</>
									}
									content={
										<>
											<Menu03
												number='1.3.1'
												title='Single Family '
												content={
													<>
														<TableInvestment defaultPropertyType={1} />
														<TableProperties defaultPropertyType={1} />
														{/* <TableTokens defaultPropertyType={1} /> */}
														<TableValuationAssets defaultPropertyType={1} />
													</>
												}
												defaultExpand={false}
											/>

											<Menu03
												number='1.3.2'
												title='Multi Family '
												content={
													<>
														<TableInvestment defaultPropertyType={2} />
														<TableProperties defaultPropertyType={2} />
														{/* <TableTokens defaultPropertyType={2} /> */}
														<TableValuationAssets defaultPropertyType={2} />
													</>
												}
												defaultExpand={false}
											/>
											<Menu03
												number='1.3.3'
												title='Duplex '
												content={
													<>
														<TableInvestment defaultPropertyType={3} />
														<TableProperties defaultPropertyType={3} />
														{/* <TableTokens defaultPropertyType={3} /> */}
														<TableValuationAssets defaultPropertyType={3} />
													</>
												}
												defaultExpand={false}
											/>
											<Menu03
												number='1.3.4'
												title='SFR Portfolio '
												content={
													<>
														<TableInvestment defaultPropertyType={10} />
														<TableProperties defaultPropertyType={10} />
														{/* <TableTokens defaultPropertyType={10} /> */}
														<TableValuationAssets defaultPropertyType={10} />
													</>
												}
												defaultExpand={false}
											/>
											<Menu03
												number='1.3.5'
												title='Quadplex'
												content={
													<>
														<TableInvestment defaultPropertyType={8} />
														<TableProperties defaultPropertyType={8} />
														{/* <TableTokens defaultPropertyType={8} /> */}
														<TableValuationAssets defaultPropertyType={8} />
													</>
												}
												defaultExpand={false}
											/>
										</>
									}
								/>
							</>
						}
					/>
					<Menu01
						number='2'
						title='Property Analysis'
						description='Dive deep into property analytics with pie charts and widgets that break down property types, investment returns, and other critical data points.'
						content={
							<>
								<Menu02
									number='2.1'
									title='Detailed Property Composition Analysis'
									description='Explore the nuances of realT property portfolio with comprehensive visualizations. Delve into the composition and diversity of property types and the distribution of investment returns with specialized pie charts and analytical widgets.'
									content={
										<>
											<PiePropertyType />
											<PiePropertyDiversity />
										</>
									}
								/>
								<Menu02
									number='2.2'
									title='Yield Property Analysis'
									// description='Dive deep into realT portfolio with our Interactive Property Analysis tool. Examine and understand the distribution, yields, and types of properties. Get a clear visual breakdown and gain valuable insights to make informed decisions.'
									description='Examine and understand the distribution, yields, and types of properties. Get a clear visual breakdown and gain valuable insights to make informed decisions.'
									content={
										<>
											<BarPropertyYield />
										</>
									}
								/>
								<Menu02
									number='2.3'
									title='Prices Property Analysis'
									description="Dive deep into the analysis of property prices. Gain understanding of the distribution and specific characteristics of each asset, whether it's an analysis per door, per square foot, or per square meter, to make well-informed decisions."
									content={
										<>
											<TablePropertyPrice />
										</>
									}
								/>
							</>
						}
					/>
					<Menu01
						number='3'
						title='Valuation of RealToken Assets'
						description="Get a comprehensive overview of your RealT investments. Explore detailed tables and key metrics for a quick, informed glance at your real estate portfolio's performance."
						content={
							<>
								<TableTokenValuation />
							</>
						}
					/>

					{holderWallets && (
						<Menu01
							number='4'
							title='Pools Levinswap'
							description={
								<>
									List of Levinswap Pools where RealT deposits RealTokens and offers the rents of all other participants in these pools. <br />
									The <strong>Rent and Yield</strong> are calculated per Year.
									<br />
									Levinswap Pools are refresh every hour. <br /> <br />
									<em>
										You can start by staking 1 or 2 dollars into a pool and wait until the following week to measure its effect. Don't rush, try it
										out first to learn how it works.
									</em>
									<br />
									<br />
									<strong>Link to stake on pool : </strong>
									<a href='https://app.levinswap.realt.community/#/add/ETH' target='_blank'>
										https://app.levinswap.realt.community/#/add/ETH
									</a>
									<br />
									<strong>Link to learn how to stake on Tuto Community : </strong>
									<a href='https://community-realt.gitbook.io/tuto-community/defi-realt/dex-swap/levinswap' target='_blank'>
										https://community-realt.gitbook.io/tuto-community/defi-realt/dex-swap/levinswap
									</a>
									<br />
									<br />
								</>
							}
							content={
								<>
									<Menu02
										number='4.1'
										title='Pools Levinswap : USDC'
										description={
											<>
												By default the list is sorted by <strong>"Pool Yield"</strong> to show you the most interesting first. You have just to set
												the number of <strong>"Tokens Add"</strong> to view the new Yield.
											</>
										}
										content={<>{<TableLevinswap coinFilter={"USD"} />}</>}
									/>
									<Menu02
										number='4.2'
										title='Pools Levinswap : WETH'
										description={
											<>
												By default the list is sorted by <strong>"Pool Yield"</strong> to show you the most interesting first. You have just to set
												the number of <strong>"Tokens Add"</strong> to view the new Yield.
											</>
										}
										content={<>{<TableLevinswap coinFilter={"Ether"} />}</>}
									/>
									<Menu02
										number='4.3'
										title='Pools Levinswap : LEVIN'
										description={
											<>
												By default the list is sorted by <strong>"Pool Yield"</strong> to show you the most interesting first. You have just to set
												the number of <strong>"Tokens Add"</strong> to view the new Yield.
											</>
										}
										content={<>{<TableLevinswap coinFilter={"Levin"} />}</>}
									/>
									{/* <Menu02 number='4.4' title='Pools Levinswap : All' description={<>Sort by <strong>"Pool Yield"</strong> to find the most interesting and adjust the number of <strong>"Tokens Add"</strong> to view the new Yield.</>} content={<>{<TableLevinswap  />}</>} /> */}
								</>
							}
						/>
					)}
				</Box>
			)}
		</Box>
	);

	return returnValue;
}

export default RealTokens;
