import React, { useContext, useEffect, useState } from "react";
import { SettingsContext } from "../../context/settings-context";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import "leaflet/dist/leaflet.css"; // Importer les styles de Leaflet
import LoadingWebSite from "../../components/Common/LoadingWebSite";
import Header from "../../components/Header/Header";

import L from "leaflet";
import { Box, Typography, Button } from "@mui/material";

// Définir l'icône de style goutte d'eau
const customIcon = L.icon({
	iconUrl: "https://cdn-icons-png.flaticon.com/512/252/252025.png", // URL de l'icône goutte d'eau (modifiable)
	iconSize: [32, 32], // Taille de l'icône
	iconAnchor: [16, 32], // Point d'ancrage de l'icône (au bas de l'icône)
	popupAnchor: [0, -32], // Position du popup par rapport à l'icône
});

function VisitorsMapView({ defaultPropertyType = 0 }) {
	const { balanceWallets, holderWallets } = useContext(SettingsContext);
	const { realTokens } = useContext(SettingsContext);
	const [points, setPoints] = useState([]);
	const [mapCenter, setMapCenter] = useState([48.8566, 2.3522]); // Centre par défaut (Paris)
	const [showLegend, setShowLegend] = useState(false);
	const [ethChain, setEthChain] = useState(true);
	const [xdaiChain, setXdaiChain] = useState(true);
	const [rmmChain, setRmmChain] = useState(true);
	const [rmmv3Chain, setRmmv3Chain] = useState(true);
	const [poolChain, setPoolChain] = useState(true);
	const [update7, setUpdate7] = useState(false);
	const [update30, setUpdate30] = useState(false);
	const [tokensBalances, setTokensBalances] = useState(null);

	useEffect(() => {
		if (!realTokens) return;

		let tokens = [
			...realTokens.list.filter((data) => !data.shortName.startsWith("OLD") && data.totalTokens > 0 && data.productType !== "equity_token"),
		];
		if (defaultPropertyType > 0) tokens = tokens.filter((token) => token.propertyType === defaultPropertyType);

		console.log("tokens", tokens[0]);

		// Extraction des points GPS (name, lat, lng)
		const extractedPoints = tokens.map((token) => ({
			id: token.id,
			name: token.shortName,
			position: [parseFloat(token.coordinate.lat), parseFloat(token.coordinate.lng)], // Conversion des coordonnées
			token: token,
		}));

		extractedPoints.sort((a, b) => {
			const aa = a.token.initialLaunchDate;
			const bb = b.token.initialLaunchDate;
			return new Date(aa.date) - new Date(bb.date);
		});

		// Mettre à jour l'état avec les points extraits
		setPoints(extractedPoints);

		// Centrer la carte sur le premier point s'il existe
		if (extractedPoints.length > 0) setMapCenter(extractedPoints[0].position);
	}, [realTokens, defaultPropertyType]);

	console.table(points);

	// const setChips() =>{
	// 	tokenBalance.tokens.findIndex((t) => t.id === realToken.id);

	// }

	// if(!tokensBalances) return <></>

	return (
		<>
			{points.length > 0 && (
				<>
					<MapContainer center={mapCenter} zoom={5} style={{ height: "100%", width: "100%" }}>
						{/* TileLayer pour afficher la carte */}
						<TileLayer
							url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
							attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
						/>

						{/* Affichage des points sur la carte */}
						{points.map((point) => (
							<Marker key={point.id} position={point.position} icon={customIcon}>
								<Popup>{point.name}</Popup>
							</Marker>
						))}
					</MapContainer>
				</>
			)}
		</>
	);
}

export default VisitorsMapView;
