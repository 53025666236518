import React, { useContext, useState } from "react";
import { SettingsContext } from "../../../context/settings-context";
import { Box, Typography, Paper, TextField, MenuItem, Select, InputAdornment } from "@mui/material";
import { BarChart } from "@mui/x-charts";

function BarPropertyYield() {
	const { realTokens, getNextRonday, holderWallets, balanceWallets, DEFAULT_PROPERTY_TYPE, settingsDashboard, setSettingsDashboard } =
		useContext(SettingsContext);
	const [yieldIncrement, setYieldIncrement] = useState(1);

	if (!settingsDashboard.isExpandedCharts) return <></>;
	if (!realTokens) return <></>;
	if (!holderWallets) return <></>;
	if (!holderWallets.filter((holderWallet) => holderWallet.checked)[0]) return <></>;
	if (!DEFAULT_PROPERTY_TYPE) return <></>;
	let tokens = [
		...realTokens.list.filter((data) => !data.shortName.startsWith("OLD") && data.totalTokens > 0 && data.productType !== "equity_token"),
	];
	if (settingsDashboard.selectedPropertyType > 0) tokens = tokens.filter((token) => token.propertyType === settingsDashboard.selectedPropertyType);

	const selectedRentType = "Year";
	let tokensBalance = [{ label: "BarPropertyYield", chain: "total", count: 0, rent: 0, total: 0, yield: 0, tokens: [] }];

	if (balanceWallets) {
		tokensBalance.forEach((tokenBalance) => {
			holderWallets
				.filter((holderWallet) => holderWallet.checked)
				.forEach((holderWallet) => {
					for (const wallet of Object.keys(balanceWallets)) {
						if (holderWallet.address === wallet) {
							for (const chain of Object.keys(balanceWallets[wallet].tokens)) {
								if (tokenBalance.chain === "eth" || tokenBalance.chain === "total") {
									if (chain === "eth") {
										const tokensList = balanceWallets[wallet].tokens[chain].tokens;
										if (tokensList)
											tokensList.forEach((token) => {
												const realToken = tokens.filter((t) => t.ethereumContract === token.token.address)[0];
												if (realToken) {
													if (Date.now() > getNextRonday(realToken)) {
														if (selectedRentType === "Day") tokenBalance.count += parseFloat(token.amount * realToken.netRentDayPerToken);
														if (selectedRentType === "Week") tokenBalance.count += parseFloat(token.amount * realToken.netRentDayPerToken * 7);
														if (selectedRentType === "Month") tokenBalance.count += parseFloat(token.amount * realToken.netRentMonthPerToken);
														if (selectedRentType === "Year") tokenBalance.count += parseFloat(token.amount * realToken.netRentYearPerToken);
														tokenBalance.rent += parseFloat(token.amount * realToken.netRentYearPerToken);
														tokenBalance.total += parseFloat(token.amount * realToken.tokenPrice);
														tokenBalance.yield = parseFloat(token.rent / tokenBalance.total);
														let existingTokenIndex = tokenBalance.tokens.findIndex((t) => t.id === realToken.id);
														if (existingTokenIndex >= 0) {
															tokenBalance.tokens[existingTokenIndex].amount += parseFloat(token.amount);
															tokenBalance.tokens[existingTokenIndex].chain.push({
																id: "eth",
																amount: parseFloat(token.amount),
																wallet: wallet,
															});
														} else {
															// If the token doesn't exist, push a new entry
															tokenBalance.tokens.push({
																id: realToken.id,
																token: realToken,
																amount: parseFloat(token.amount),
																chain: [{ id: "eth", amount: parseFloat(token.amount), wallet: wallet }],
															});
														}
													}
												}
											});
									}
								}

								if (tokenBalance.chain === "xdai" || tokenBalance.chain === "total") {
									if (chain === "xdai") {
										const tokensList = balanceWallets[wallet].tokens[chain].tokens;
										if (tokensList)
											tokensList.forEach((token) => {
												const realToken = tokens.filter((t) => t.gnosisContract === token.token.address)[0];
												if (realToken) {
													getNextRonday(realToken);
													if (Date.now() > getNextRonday(realToken)) {
														if (selectedRentType === "Day") tokenBalance.count += parseFloat(token.amount * realToken.netRentDayPerToken);
														if (selectedRentType === "Week") tokenBalance.count += parseFloat(token.amount * realToken.netRentDayPerToken * 7);
														if (selectedRentType === "Month") tokenBalance.count += parseFloat(token.amount * realToken.netRentMonthPerToken);
														if (selectedRentType === "Year") tokenBalance.count += parseFloat(token.amount * realToken.netRentYearPerToken);
														tokenBalance.rent += parseFloat(token.amount * realToken.netRentYearPerToken);
														tokenBalance.total += parseFloat(token.amount * realToken.tokenPrice);
														tokenBalance.yield = parseFloat(token.rent / tokenBalance.total);
														let existingTokenIndex = tokenBalance.tokens.findIndex((t) => t.id === realToken.id);
														if (existingTokenIndex >= 0) {
															tokenBalance.tokens[existingTokenIndex].amount += parseFloat(token.amount);
															tokenBalance.tokens[existingTokenIndex].chain.push({
																id: "xdai",
																amount: parseFloat(token.amount),
																wallet: wallet,
															});
															// console.log("add", realToken.shortName, realToken.annualPercentageYield);
														} else {
															// If the token doesn't exist, push a new entry
															tokenBalance.tokens.push({
																id: realToken.id,
																token: realToken,
																amount: parseFloat(token.amount),
																chain: [{ id: "xdai", amount: parseFloat(token.amount), wallet: wallet }],
															});
															// console.log("new", realToken.shortName, realToken.annualPercentageYield);
														}
													}
												}
											});
									}
								}

								if (tokenBalance.chain === "rmm" || tokenBalance.chain === "total") {
									if (chain === "rmm") {
										const tokensList = balanceWallets[wallet].tokens[chain].tokens;
										if (tokensList)
											tokensList.forEach((token) => {
												const rmmBalance = parseInt(token.currentATokenBalance) / 10 ** token.reserve.decimals;
												if (token.reserve.underlyingAsset !== "0xe91d153e0b41518a2ce8dd3d7944fa863463a97d") {
													const realToken = tokens.filter((t) => t.gnosisContract === token.reserve.underlyingAsset)[0];
													if (realToken) {
														if (Date.now() > getNextRonday(realToken)) {
															if (selectedRentType === "Day") tokenBalance.count += parseFloat(rmmBalance * realToken.netRentDayPerToken);
															if (selectedRentType === "Week") tokenBalance.count += parseFloat(rmmBalance * realToken.netRentDayPerToken * 7);
															if (selectedRentType === "Month") tokenBalance.count += parseFloat(rmmBalance * realToken.netRentMonthPerToken);
															if (selectedRentType === "Year") tokenBalance.count += parseFloat(rmmBalance * realToken.netRentYearPerToken);
															tokenBalance.rent += parseFloat(rmmBalance * realToken.netRentYearPerToken);
															tokenBalance.total += parseFloat(rmmBalance * realToken.tokenPrice);
															tokenBalance.yield = parseFloat(token.rent / tokenBalance.total);
															let existingTokenIndex = tokenBalance.tokens.findIndex((t) => t.id === realToken.id);
															if (existingTokenIndex >= 0) {
																// console.log("Update rmm", realToken.fullName, tokenBalance.tokens[existingTokenIndex].amount, rmmBalance);
																tokenBalance.tokens[existingTokenIndex].amount += parseFloat(rmmBalance);
																tokenBalance.tokens[existingTokenIndex].chain.push({
																	id: "rmm",
																	amount: parseFloat(rmmBalance),
																	wallet: wallet,
																});
															} else {
																// If the token doesn't exist, push a new entry
																tokenBalance.tokens.push({
																	id: realToken.id,
																	token: realToken,
																	amount: parseFloat(rmmBalance),
																	chain: [{ id: "rmm", amount: parseFloat(rmmBalance), wallet: wallet }],
																});
															}
														}
													}
												}
											});
									}
								}

								if (tokenBalance.chain === "rmmv3" || tokenBalance.chain === "total") {
									if (chain === "rmmv3") {
										const tokensList = balanceWallets[wallet].tokens[chain].tokens;
										if (tokensList)
											tokensList.forEach((token) => {
												// const rmmBalance = parseInt(token.currentATokenBalance) / 10 ** token.reserve.decimals;
												const rmmBalance = parseInt(token.amount) / 10 ** token.token.decimals;
												if (token.token.id !== "0xe91d153e0b41518a2ce8dd3d7944fa863463a97d") {
													const realToken = tokens.filter((t) => t.gnosisContract === token.token.id.toLowerCase())[0];
													if (realToken) {
														if (Date.now() > getNextRonday(realToken)) {
															if (selectedRentType === "Day") tokenBalance.count += parseFloat(rmmBalance * realToken.netRentDayPerToken);
															if (selectedRentType === "Week") tokenBalance.count += parseFloat(rmmBalance * realToken.netRentDayPerToken * 7);
															if (selectedRentType === "Month") tokenBalance.count += parseFloat(rmmBalance * realToken.netRentMonthPerToken);
															if (selectedRentType === "Year") tokenBalance.count += parseFloat(rmmBalance * realToken.netRentYearPerToken);
															tokenBalance.rent += parseFloat(rmmBalance * realToken.netRentYearPerToken);
															tokenBalance.total += parseFloat(rmmBalance * realToken.tokenPrice);
															tokenBalance.yield = parseFloat(token.rent / tokenBalance.total);
															let existingTokenIndex = tokenBalance.tokens.findIndex((t) => t.id === realToken.id);
															if (existingTokenIndex >= 0) {
																// console.log("Update rmm", realToken.fullName, tokenBalance.tokens[existingTokenIndex].amount, rmmBalance);
																tokenBalance.tokens[existingTokenIndex].amount += parseFloat(rmmBalance);
																tokenBalance.tokens[existingTokenIndex].chain.push({
																	id: "rmm",
																	amount: parseFloat(rmmBalance),
																	wallet: wallet,
																});
															} else {
																// If the token doesn't exist, push a new entry
																tokenBalance.tokens.push({
																	id: realToken.id,
																	token: realToken,
																	amount: parseFloat(rmmBalance),
																	chain: [{ id: "rmm", amount: parseFloat(rmmBalance), wallet: wallet }],
																});
															}
														}
													}
												}
											});
									}
								}

								if (tokenBalance.chain === "pool" || tokenBalance.chain === "total") {
									if (chain === "pool") {
										const tokensList = balanceWallets[wallet].tokens[chain].tokens;
										if (tokensList)
											tokensList.forEach((token) => {
												// console.log("chain", tokenBalance.chain, "walletBalance:", wallet, "token", token);
												const liquidityTokenBalance = parseFloat(token.liquidityTokenBalance);
												const totalSupply = parseFloat(token.pair.totalSupply);
												if (tokens.filter((t) => t.gnosisContract === token.pair.token0.id)[0]) {
													const realToken = tokens.filter((t) => t.gnosisContract === token.pair.token0.id)[0];
													if (realToken) {
														const poolBalance = (liquidityTokenBalance * token.pair.token0.liquidity) / totalSupply;
														const realtNbToken = realToken.pool.nbTokenRealt * realToken.pool.realtRatio;
														const holderNbToken = realToken.pool.nbTokenRealt * realToken.pool.holderRatio;
														const bonusToken = (realtNbToken * poolBalance) / holderNbToken;
														if (Date.now() > getNextRonday(realToken)) {
															if (selectedRentType === "Day")
																tokenBalance.count += parseFloat((poolBalance + bonusToken) * realToken.netRentDayPerToken);
															if (selectedRentType === "Week")
																tokenBalance.count += parseFloat((poolBalance + bonusToken) * realToken.netRentDayPerToken * 7);
															if (selectedRentType === "Month")
																tokenBalance.count += parseFloat((poolBalance + bonusToken) * realToken.netRentMonthPerToken);
															if (selectedRentType === "Year")
																tokenBalance.count += parseFloat((poolBalance + bonusToken) * realToken.netRentYearPerToken);
															tokenBalance.rent += parseFloat((poolBalance + bonusToken) * realToken.netRentYearPerToken);
															tokenBalance.total += parseFloat(poolBalance * realToken.tokenPrice * 2);
															tokenBalance.yield = parseFloat(token.rent / tokenBalance.total);
															let existingTokenIndex = tokenBalance.tokens.findIndex((t) => t.id === realToken.id);
															if (existingTokenIndex >= 0) {
																tokenBalance.tokens[existingTokenIndex].amount += parseFloat(poolBalance);
																tokenBalance.tokens[existingTokenIndex].chain.push({
																	id: "pool",
																	amount: parseFloat(poolBalance),
																	wallet: wallet,
																});
															} else {
																// If the token doesn't exist, push a new entry
																tokenBalance.tokens.push({
																	id: realToken.id,
																	token: realToken,
																	amount: parseFloat(poolBalance),
																	chain: [{ id: "pool", amount: parseFloat(poolBalance), wallet: wallet }],
																});
															}
														}
													}
												}
												if (tokens.filter((t) => t.gnosisContract === token.pair.token1.id)[0]) {
													const realToken = tokens.filter((t) => t.gnosisContract === token.pair.token1.id)[0];
													if (realToken) {
														const poolBalance = (liquidityTokenBalance * token.pair.token1.liquidity) / totalSupply;
														const realtNbToken = realToken.pool.nbTokenRealt * realToken.pool.realtRatio;
														const holderNbToken = realToken.pool.nbTokenRealt * realToken.pool.holderRatio;
														const bonusToken = (realtNbToken * poolBalance) / holderNbToken;
														if (Date.now() > getNextRonday(realToken)) {
															if (selectedRentType === "Day")
																tokenBalance.count += parseFloat((poolBalance + bonusToken) * realToken.netRentDayPerToken);
															if (selectedRentType === "Week")
																tokenBalance.count += parseFloat((poolBalance + bonusToken) * realToken.netRentDayPerToken * 7);
															if (selectedRentType === "Month")
																tokenBalance.count += parseFloat((poolBalance + bonusToken) * realToken.netRentMonthPerToken);
															if (selectedRentType === "Year")
																tokenBalance.count += parseFloat((poolBalance + bonusToken) * realToken.netRentYearPerToken);
															tokenBalance.rent += parseFloat((poolBalance + bonusToken) * realToken.netRentYearPerToken);
															tokenBalance.total += parseFloat(poolBalance * realToken.tokenPrice * 2);
															tokenBalance.yield = parseFloat(token.rent / tokenBalance.total);
															let existingTokenIndex = tokenBalance.tokens.findIndex((t) => t.id === realToken.id);
															if (existingTokenIndex >= 0) {
																tokenBalance.tokens[existingTokenIndex].amount += parseFloat(poolBalance);
																tokenBalance.tokens[existingTokenIndex].chain.push({
																	id: "pool",
																	amount: parseFloat(poolBalance),
																	wallet: wallet,
																});
															} else {
																// If the token doesn't exist, push a new entry
																tokenBalance.tokens.push({
																	id: realToken.id,
																	token: realToken,
																	amount: parseFloat(poolBalance),
																	chain: [{ id: "pool", amount: parseFloat(poolBalance), wallet: wallet }],
																});
															}
														}
													}
												}
											});
									}
								}
							}
						}
					}
				});
		});
	}

	// console.log("BarPropertyYield - balanceWallets", balanceWallets);
	// console.log("BarPropertyYield - tokensBalance", tokensBalance[0]);

	if (!tokensBalance[0].tokens[0]) return <></>;
	const yieldCounts = tokensBalance[0].tokens.reduce((acc, token) => {
		// console.log("token", token.token.shortName, token.token.annualPercentageYield);
		const yieldCategory = Math.floor(token.token.annualPercentageYield / yieldIncrement) * yieldIncrement; // Rounds down to the nearest multiple of 2
		const label = `<${yieldCategory + yieldIncrement < 10 ? "0" + (yieldCategory + yieldIncrement) : yieldCategory + yieldIncrement}%`;
		acc[label] = (acc[label] || 0) + 1;
		return acc;
	}, {});

	const data = Object.entries(yieldCounts)
		.sort((a, b) => a[0].localeCompare(b[0]))
		.map(([label, count]) => ({
			value: count,
			label: label,
		}));

	const chartSetting = {
		width: 700,
		height: 325,
	};

	const selectedRentTypeChange = (newValue) => {
		setSettingsDashboard((prevSettings) => ({
			...prevSettings,
			selectedRentType: newValue,
		}));
	};

	const selectedPropertyTypeChange = (newValue) => {
		setSettingsDashboard((prevSettings) => ({
			...prevSettings,
			selectedPropertyType: newValue,
		}));
	};

	// console.log("yieldCounts:", yieldCounts);
	// console.log("BarPropertyYield");

	const size_width = 500;
	const size_height = 350;
	
	return (
		<Paper elevation={3} sx={{ m: 1, minWidth: 500, border: 1, borderColor: "primary.main", borderRadius: 4 }}>
			<Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", m: 0 }}>
				<Typography variant='h5' sx={{ m: 1, ml: 0, color: "primary.main" }}>
					Property Type
				</Typography>
				<Select
					sx={{ mt: 0, ml: 0, minWidth: 125 }}
					value={settingsDashboard.selectedPropertyType}
					onChange={(e) => {
						selectedPropertyTypeChange(e.target.value);
					}}
					size='small'
				>
					{DEFAULT_PROPERTY_TYPE.filter((p) => !p.label.startsWith("Text_")).map((p) => (
						<MenuItem key={p.index} value={p.index}>
							{p.label}
						</MenuItem>
					))}
				</Select>
				<Typography variant='h5' sx={{ m: 1, ml: 3, color: "primary.main" }}>
					Yield Step
				</Typography>
				<TextField
					sx={{
						mt: 0,
						ml: 0,
						minWidth: 40,
						padding: "5px 6px", // Adjust the padding as needed
					}}
					onChange={(e) => setYieldIncrement(parseInt(e.target.value))}
					variant='outlined'
					type='number'
					size='small'
					value={yieldIncrement}
					inputProps={{
						endAdornment: <InputAdornment position='end'>%</InputAdornment>,
						min: 1,
						max: 10,
						step: 1,
						style: {
							textAlign: "center",
							padding: "5px 6px",
						},
					}}
				/>
			</Box>
			<Box
				sx={{
					flexWrap: "wrap",
					mr: 1,
					p: 0,
					border: 0,
					borderColor: "primary.main",
					borderRadius: 4,
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
				}}
			>
				<Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", pl: 1 }}>
					<Typography
						variant='body1'
						sx={{
							width: 30,
							my: 0,
							ml: 0,
							color: "primary.main",
							writingMode: "vertical-lr",
							transform: "rotate(180deg)",
						}}
					>
						Number of Property by Yield
					</Typography>
				</Box>
				<BarChart
					sx={{ pb: 1 }}
					xAxis={[{ scaleType: "band", data: data.map((d) => d.label) }]}
					series={[{ data: data.map((d) => d.value) , label: "Nb Properties",}]}
					{...chartSetting}
					margin={{ left: 35, right: 0, top: 10, bottom: 20 }}
				/>
			</Box>
		</Paper>
	);
}

export default BarPropertyYield;
