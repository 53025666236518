import React, { createContext, useEffect, useState, useRef, useContext } from "react";
import Web3 from "web3";
import { SettingsContext } from "./settings-context";
import { RealTokensContext } from "./realTokens-context";

const tracesCoins = false;

export const ApiCoinGeckoContext = createContext();

export const ApiCoinGeckoProvider = (props) => {
	const { blockchainsClient, setBlockchainsClient, setBalanceWallets, historyWallets, setHistoryWallets } = useContext(SettingsContext);
	const { balanceWallets, settingsWallet, settingsApiCoinGecko } = useContext(SettingsContext);
	const { toUpdateTask, finishTask } = useContext(RealTokensContext);

	const TIMEOUT_REFRESH = 5000; // Timer pour la vérif de refresh des convert/balance en millisecondes
	const TIMEOUT_FETCH = 2500; // Timer pour la vérif de refresh des convert/balance en millisecondes

	// -------------------------------------------------------
	// ---- Timer pour mise à jour des prix des tokens    ----
	// -------------------------------------------------------

	const [state, setState] = useState({ num: 0 });
	const counter = useRef(1);
	useEffect(() => {
		if (toUpdateTask.length > 0) {
			// console.log("List of task(s) ApiCoinGeckoProvider :", toUpdateTask);
			const [firstTask, ...remainingTasks] = toUpdateTask;

			executeTask(firstTask);
		}

		counter.current += 1;
		const timer = setTimeout(() => setState({ num: counter.current }), TIMEOUT_REFRESH);
		return () => clearTimeout(timer);
	}, [state]);

	const executeTask = async (task) => {
		// console.log(`Executing task: ${task.name}`);

		if (task.name === "getCoinsValuesToUsd") await getCoinsValuesToUsd();
		if (task.name === "getCoinsBalance") await getCoinsBalance();
	};

	// --------------------------------------------------------
	// ---- Reccupération de la conversion de chaque coins ----
	// --------------------------------------------------------

	function findJSONValueDifferences(obj1, obj2, path = "") {
		const differences = {};

		for (const key in obj1) {
			if (obj1.hasOwnProperty(key)) {
				const value1 = obj1[key];
				const value2 = obj2[key];

				if (typeof value1 === "object" && typeof value2 === "object") {
					const nestedDifferences = findJSONValueDifferences(value1, value2, `${path}.${key}`);
					if (Object.keys(nestedDifferences).length > 0) {
						differences[key] = nestedDifferences;
					}
				} else if (value1 !== value2) {
					differences[`${path}.${key}`] = {
						oldValue: value1,
						newValue: value2,
					};
				}
			}
		}

		for (const key in obj2) {
			if (obj2.hasOwnProperty(key)) {
				if (!obj1.hasOwnProperty(key)) {
					differences[`${path}.${key}`] = {
						oldValue: undefined,
						newValue: obj2[key],
					};
				}
			}
		}

		// if (path === "") console.log("differences", path, differences);
		return differences;
	}

	const getCoinsValuesToUsd = async () => {
		const start_time = Date.now();
		let cptUpdate = 0;
		if (blockchainsClient) {
			try {
				let newBlockchainClient = JSON.parse(JSON.stringify(blockchainsClient)) || {};
				let isModify = false;
				for (const chain of Object.keys(newBlockchainClient)) {
					for (const coin of Object.keys(newBlockchainClient[chain].coinList)) {
						let Coin = newBlockchainClient[chain].coinList[coin];
						if (tracesCoins)  console.log("blockchainsClient[" + chain + "][" + coin + "] to Refresh ? ", start_time + TIMEOUT_FETCH > Date.now(),cptUpdate);
						if (start_time + TIMEOUT_FETCH > Date.now() && cptUpdate < 5) {
							if (Coin)
								try {
									if (!Coin.timestamp) Coin.timestamp = 0;
									if (Coin.timestamp + settingsApiCoinGecko.timerUpdateCoinsConvertion < Date.now() || Coin.toRefresh) {
										cptUpdate++;
										if (Coin.api) {
											Coin.toUSD = await getCoinToUsd(Coin);
										} else {
											if (Coin.name === "RWA") Coin.toUSD = 50;
											else if (Coin.name === "SOON") Coin.toUSD = 1;
											else Coin.toUSD = 1;
										}
										Coin.timestamp = Date.now();
										Coin.isError = false;
										Coin.toRefresh = false;
										if (tracesCoins) console.log("getCoinsValuesToUsd[" + chain + "][" + coin + "] = ", Coin.toUSD);

										for (const chainCopy of Object.keys(newBlockchainClient)) {
											for (const coinCopy of Object.keys(newBlockchainClient[chainCopy].coinList)) {
												let CoinCopy = newBlockchainClient[chainCopy].coinList[coinCopy];
												if (CoinCopy) {
													if (CoinCopy.api && Coin.api) {
														if (CoinCopy.api === Coin.api && (chainCopy !== chain || coinCopy !== coin)) {
															CoinCopy.toUSD = Coin.toUSD;
															CoinCopy.timestamp = Coin.timestamp;
															CoinCopy.isError = Coin.isError;
															CoinCopy.toRefresh = Coin.toRefresh;
															if (tracesCoins) console.log("getCoinsValuesToUsd[" + chainCopy + "][" + coinCopy + "] = ", CoinCopy.toUSD);
														}
													}
												}
												// if (Object.keys(findJSONValueDifferences(CoinCopy, newBlockchainClient[chainCopy].coinList[coinCopy])).length !== 0)
												newBlockchainClient[chainCopy].coinList[coinCopy] = CoinCopy;
											}
										}
									}
								} catch (error) {
									if (error.message.includes("Failed to fetch")) {
										console.log("ERROR getCoinsValuesToUsd", chain, coin, "- api error (", Coin.api, ")");
									} else {
										console.log("ERROR getCoinsValuesToUsd", chain, coin, Coin, "- fetch error :", error);
										if (tracesCoins) console.log("ERROR getCoinsValuesToUsd", Object.keys(newBlockchainClient));
										if (tracesCoins) console.log("ERROR getCoinsValuesToUsd", Object.keys(newBlockchainClient[chain].coinList));
									}
									Coin.timestamp = Date.now() - settingsApiCoinGecko.timerUpdateCoinsConvertion + 60000;
									Coin.isError = true;
									Coin.toRefresh = false;
								}
							newBlockchainClient[chain].coinList[coin] = Coin;
						} else {
							break;
						}
					}
				}
				if (Object.keys(findJSONValueDifferences(newBlockchainClient, blockchainsClient)).length !== 0) setBlockchainsClient(newBlockchainClient);
			} catch (error) {
				console.log("ERROR global getCoinsValuesToUsd:", error);
			}
		}
		finishTask("getCoinsValuesToUsd");
	};

	function getCoinToUsd(coin) {
		return fetch(coin.api)
			.then(function (response) {
				return response.json();
			})
			.then(function (data) {
				return data.market_data.current_price.usd;
			});
	}

	// --------------------------------------------------
	// ---- Reccupération du Balance de chaque coins ----
	// --------------------------------------------------
	const getCoinsBalance = async () => {
		const start_time = Date.now();
		let cptUpdate = 0;
		let newBalances = JSON.parse(JSON.stringify(balanceWallets)) || {};
		let newHistoBalances = JSON.parse(JSON.stringify(historyWallets)) || {};
		if (!newBalances) newBalances = {};
		if (blockchainsClient && balanceWallets) {
			try {
				for (const wallet of Object.keys(newBalances)) {
					if (newBalances[wallet].coins) {
						for (const chain of Object.keys(newBalances[wallet].coins)) {
							let bc = blockchainsClient[chain];
							const web3 = new Web3(bc.rpc);

							if (start_time + TIMEOUT_FETCH > Date.now()) {
								if (web3) {
									for (const coin of Object.keys(newBalances[wallet].coins[chain])) {
										let Coin = JSON.parse(JSON.stringify(newBalances[wallet].coins[chain][coin])) || {};
										if (bc.coinList[coin] && cptUpdate < 10) {
											// if (tracesCoins) console.log(
											// 	"balanceWallets[..." + wallet.slice(-4) + "].coins[" + chain + "][" + coin + "] = ",Coin.timestamp + settingsApiCoinGecko.timerUpdateCoinsBalance < Date.now(),cptUpdate)
												
											if (bc.coinList[coin].id === 0) {
												try {
													if (
														!isNaN(bc.coinList[coin].toUSD) &&
														(Coin.timestamp + settingsApiCoinGecko.timerUpdateCoinsBalance < Date.now() || Coin.toRefresh || !("usd" in Coin))
													) {
														cptUpdate++;
														Coin.balance = parseFloat(parseInt(await web3.eth.getBalance(wallet)) / 10 ** 18);
														Coin.usd = parseFloat(bc.coinList[coin].toUSD);
														Coin.timestamp = Date.now();
														Coin.isError = false;
														Coin.toRefresh = false;
														if (tracesCoins)
															console.log(
																"balanceWallets[..." + wallet.slice(-4) + "].coins[" + chain + "][" + coin + "] = ",
																Coin.balance,
																"x",
																Coin.usd,
																"=",
																(Coin.balance * Coin.usd).toFixed(2)
															);
														newBalances[wallet].coins[chain][coin] = Coin;
														newHistoBalances[wallet].coins[chain][coin] = cleanUpCoinsBalanceHistory(Coin, wallet, chain, coin, bc.coinList[coin]);
													}
												} catch (error) {
													// TypeError: Failed to fetch
													console.log("ERROR getCoinsBalance", wallet.slice(-4), chain, coin, "- fetch error :", error);
													if (!Coin.timestamp) Coin.timestamp = 0;
													else Coin.timestamp = Coin.timestamp + 60000;
													Coin.isError = true;
													Coin.toRefresh = false;
													newBalances[wallet].coins[chain][coin] = Coin;
												}
											} else {
												try {
													if (
														!isNaN(bc.coinList[coin].toUSD) &&
														(Coin.timestamp + settingsApiCoinGecko.timerUpdateCoinsBalance < Date.now() || Coin.toRefresh || !("usd" in Coin))
													) {														
														cptUpdate++;
														if (tracesCoins) console.log("balanceWallets[..." + "].coins[" + chain + "][" + coin + "] =", bc.coinList[coin].address.toLowerCase());
														const contract = new web3.eth.Contract(bc.coinList[coin].abi, bc.coinList[coin].address.toLowerCase());
														const res = parseInt(await contract.methods.balanceOf(wallet).call());
														Coin.balance = parseFloat(res / 10 ** bc.coinList[coin].decimal);
														Coin.usd = bc.coinList[coin].toUSD;
														Coin.timestamp = Date.now();
														Coin.isError = false;
														Coin.toRefresh = false;
														if (tracesCoins)
															console.log(
																"balanceWallets[..." + wallet.slice(-4) + "].coins[" + chain + "][" + coin + "] = ",
																Coin.balance,
																"x",
																Coin.usd,
																"=",
																(Coin.balance * Coin.usd).toFixed(2)
															);
														newBalances[wallet].coins[chain][coin] = Coin;
														newHistoBalances[wallet].coins[chain][coin] = cleanUpCoinsBalanceHistory(Coin, wallet, chain, coin, bc.coinList[coin]);
													}
												} catch (error) {
													// TypeError: Failed to fetch
													console.log("ERROR getCoinsBalance", wallet.slice(-4), chain, coin, "- fetch error :", error);
													if (Coin.timestamp + settingsApiCoinGecko.timerUpdateCoinsBalance - Date.now() <= 0)
														Coin.timestamp = Date.now() - settingsApiCoinGecko.timerUpdateCoinsConvertion + 60000;
													else Coin.timestamp = Coin.timestamp + 60000;
													Coin.isError = true;
													Coin.toRefresh = false;
													if (tracesCoins)
														console.log("ERROR getCoinsBalance", wallet.slice(-4), chain, coin, "- fetch error :", Coin.timestamp, Coin.isError);
													newBalances[wallet].coins[chain][coin] = Coin;
												}
											}
										}

										newBalances[wallet].coins[chain][coin] = Coin;
										if (Object.keys(findJSONValueDifferences(newBalances, balanceWallets)).length !== 0) setBalanceWallets(newBalances);
									}
								}
							} else {
								break;
							}
						}
					}
				}
			} catch (error) {
				console.log("ERROR getCoinsBalance:", error);
			}

			if (!balanceWallets) setBalanceWallets(newBalances);
			else if (Object.keys(findJSONValueDifferences(newBalances, balanceWallets)).length !== 0) {
				setBalanceWallets(newBalances);
			}

			if (!historyWallets) setHistoryWallets(newHistoBalances);
			else if (Object.keys(findJSONValueDifferences(newHistoBalances, historyWallets)).length !== 0) {
				setHistoryWallets(newHistoBalances);
			}
		}

		finishTask("getCoinsBalance");
	};

	const isGetCoinsBalanceToRead = async () => {
		let newBalances = JSON.parse(JSON.stringify(balanceWallets)) || {};
		if (!newBalances) newBalances = {};
		if (blockchainsClient && balanceWallets) {
			for (const wallet of Object.keys(newBalances)) {
				if (newBalances[wallet].coins) {
					for (const chain of Object.keys(newBalances[wallet].coins)) {
						let bc = blockchainsClient[chain];
						for (const coin of Object.keys(newBalances[wallet].coins[chain])) {
							let Coin = JSON.parse(JSON.stringify(newBalances[wallet].coins[chain][coin])) || {};
							if (Coin.timestamp + settingsApiCoinGecko.timerUpdateCoinsBalance < Date.now() || Coin.toRefresh) return true;
						}
					}
				}
			}
		}
		return false;
	};

	const refreshCoinsBalance = (coin) => {
		// console.log("refreshCoinsBalance", coin);
		const wallet = settingsWallet.selectedWallet;
		const chain = settingsWallet.selectedBlockchain;
		let newBalances = JSON.parse(JSON.stringify(balanceWallets)) || {};
		newBalances[wallet].coins[chain][coin].toRefresh = true;
		// console.log("newBalances", wallet, chain, coin, newBalances[wallet].coins[chain][coin]);
		setBalanceWallets(newBalances);
		let newBlockchain = JSON.parse(JSON.stringify(blockchainsClient)) || {};
		newBlockchain[chain].coinList[coin].toRefresh = true;
		// console.log("newBlockchain", wallet, chain, coin, newBlockchain[chain].coinList[coin]);
		setBlockchainsClient(newBlockchain);

		// setState(0);
		// counter.current = 0;
		// if (!isGetCoinsBalance) getCoinsBalance();
	};

	const cleanUpCoinsBalanceHistory = (CoinHisto, wallet, chain, coin, blockchainCoin) => {
		// console.log("cleanUpCoinsBalanceHistory for", wallet, coin);
		let tokensHisto = JSON.parse(JSON.stringify(historyWallets[wallet].coins[chain][coin])) || {};
		const timerHistoryze = settingsApiCoinGecko.timerHistoryzeCoinsBalance;
		let timerDelete = settingsApiCoinGecko.timerDeleteCoinsBalance;
		const keys = Object.keys(tokensHisto).sort((a, b) => a - b); // Sort timestamps
		let toHistoryze = true;

		const DayTimestamp = Date.now() - (Date.now() % (24 * 60 * 60 * 1000)) - 60 * 60 * 1000;
		// timerDelete += DayTimestamp;

		keys.forEach((timestamp) => {
			// Remove if older than 30 days
			if (DayTimestamp - timestamp > timerDelete) {
				delete tokensHisto[timestamp];
			} else {
				if (!("balance" in tokensHisto[timestamp]) || !("usd" in tokensHisto[timestamp])) {
					if (tracesCoins) console.log("Clean for", coin, "tokensHisto[", timestamp, "] = ", tokensHisto[timestamp]);
					delete tokensHisto[timestamp];
				} else {
					if (isNaN(timestamp)) delete tokensHisto[timestamp];
					else if (Date.now() - timestamp < timerHistoryze) {
						if (tracesCoins) console.log("Delete for", coin, "tokensHisto[", timestamp, "] = ", tokensHisto[timestamp]);
						toHistoryze = false;
					}
				}
			}
		});

		if ((blockchainCoin.history || blockchainCoin.usd) && toHistoryze) {
			tokensHisto[CoinHisto.timestamp] = { balance: CoinHisto.balance, usd: CoinHisto.usd };
		}

		// console.log("tokensHisto", tokensHisto);
		return tokensHisto;
	};

	const value = {
		refreshCoinsBalance,
		getCoinsValuesToUsd,
		getCoinsBalance,
	};

	return <ApiCoinGeckoContext.Provider value={value}>{props.children}</ApiCoinGeckoContext.Provider>;
};
