import React, { useContext, useEffect, useState } from "react";
import { SettingsContext } from "../../context/settings-context";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import "leaflet/dist/leaflet.css"; // Importer les styles de Leaflet
import LoadingWebSite from "../../components/Common/LoadingWebSite";
import Header from "../../components/Header/Header";

import L from "leaflet";
import { Box, Typography, Button } from "@mui/material";

// Définir l'icône de style goutte d'eau
const customIcon = L.icon({
	iconUrl: "https://cdn-icons-png.flaticon.com/512/252/252025.png", // URL de l'icône goutte d'eau (modifiable)
	iconSize: [32, 32], // Taille de l'icône
	iconAnchor: [16, 32], // Point d'ancrage de l'icône (au bas de l'icône)
	popupAnchor: [0, -32], // Position du popup par rapport à l'icône
});

function WalletsMapView({ defaultPropertyType = 0 }) {
	const { settingsLoaded, balanceWallets, holderWallets } = useContext(SettingsContext);
	const { realTokens } = useContext(SettingsContext);
	const [points, setPoints] = useState(null);
	const [mapCenter, setMapCenter] = useState([48.8566, 2.3522]); // Centre par défaut (Paris)
	const [showLegend, setShowLegend] = useState(false);
	const [ethChain, setEthChain] = useState(true);
	const [xdaiChain, setXdaiChain] = useState(true);
	const [rmmChain, setRmmChain] = useState(true);
	const [rmmv3Chain, setRmmv3Chain] = useState(true);
	const [poolChain, setPoolChain] = useState(true);
	const [update7, setUpdate7] = useState(false);
	const [update30, setUpdate30] = useState(false);
	const [tokensBalances, setTokensBalances] = useState(null);

	useEffect(() => {
		if (!realTokens) return <></>;
		if (!holderWallets) return <></>;

		let tokens = [
			...realTokens.list.filter((data) => !data.shortName.startsWith("OLD") && data.totalTokens > 0 && data.productType !== "equity_token"),
		];
		if (defaultPropertyType > 0) tokens = tokens.filter((token) => token.propertyType === defaultPropertyType);

		console.log("tokens", tokens[0]);

		const selectedRentType = "Year";

		let tokensBalance = [
			{ label: "Ethereum", chain: "eth", count: 0, rent: 0, total: 0, nbToken: 0, yield: 0, tokens: [] },
			{ label: "Gnosis", chain: "xdai", count: 0, rent: 0, total: 0, nbToken: 0, yield: 0, tokens: [] },
			{ label: "RMM", chain: "rmm", count: 0, rent: 0, total: 0, yield: 0, nbToken: 0, tokens: [] },
			{ label: "RMM v3", chain: "rmmv3", count: 0, rent: 0, total: 0, yield: 0, nbToken: 0, tokens: [] },
			{ label: "Levinswap", chain: "pool", count: 0, rent: 0, total: 0, nbToken: 0, yield: 0, tokens: [] },
			{ label: "Total", chain: "total", count: 0, rent: 0, total: 0, nbToken: 0, yield: 0, tokens: [] },
		];

		tokensBalance.forEach((tokenBalance) => {
			// tokenBalance.label = tokenBalance.ronday.toLocaleDateString();
			if (holderWallets)
				holderWallets
					.filter((holderWallet) => holderWallet.checked)
					.forEach((holderWallet) => {
						for (const wallet of Object.keys(balanceWallets)) {
							if (holderWallet.address === wallet) {
								for (const chain of Object.keys(balanceWallets[wallet].tokens)) {
									if (tokenBalance.chain === "eth" || tokenBalance.chain === "total") {
										if (chain === "eth" && ethChain) {
											const tokensList = balanceWallets[wallet].tokens[chain].tokens;
											if (tokensList)
												tokensList.forEach((token) => {
													const realToken = tokens.filter((t) => t.ethereumContract === token.token.address)[0];
													if (realToken) {
														if (selectedRentType === "Day") tokenBalance.count += parseFloat(token.amount * realToken.netRentDayPerToken);
														if (selectedRentType === "Week") tokenBalance.count += parseFloat(token.amount * realToken.netRentDayPerToken * 7);
														if (selectedRentType === "Month") tokenBalance.count += parseFloat(token.amount * realToken.netRentMonthPerToken);
														if (selectedRentType === "Year") tokenBalance.count += parseFloat(token.amount * realToken.netRentYearPerToken);
														tokenBalance.rent += parseFloat(token.amount * realToken.netRentYearPerToken);
														tokenBalance.total += parseFloat(token.amount * realToken.tokenPrice);
														tokenBalance.yield = parseFloat(token.rent / tokenBalance.total);
														let existingTokenIndex = tokenBalance.tokens.findIndex((t) => t.id === realToken.id);
														if (existingTokenIndex >= 0) {
															// console.log("Update eth", realToken.fullName, tokenBalance.tokens[existingTokenIndex].amount, token.amount);
															tokenBalance.tokens[existingTokenIndex].amount += parseFloat(token.amount);
															tokenBalance.tokens[existingTokenIndex].chain.push({
																id: "eth",
																amount: parseFloat(token.amount),
																wallet: wallet,
															});
														} else {
															// If the token doesn't exist, push a new entry
															tokenBalance.tokens.push({
																id: realToken.id,
																token: realToken,
																amount: parseFloat(token.amount),
																chain: [{ id: "eth", amount: parseFloat(token.amount), wallet: wallet }],
															});
														}
													}
												});
										}
									}

									if (tokenBalance.chain === "xdai" || tokenBalance.chain === "total") {
										if (chain === "xdai" && xdaiChain) {
											const tokensList = balanceWallets[wallet].tokens[chain].tokens;
											if (tokensList)
												tokensList.forEach((token) => {
													const realToken = tokens.filter((t) => t.gnosisContract === token.token.address)[0];
													if (realToken) {
														if (selectedRentType === "Day") tokenBalance.count += parseFloat(token.amount * realToken.netRentDayPerToken);
														if (selectedRentType === "Week") tokenBalance.count += parseFloat(token.amount * realToken.netRentDayPerToken * 7);
														if (selectedRentType === "Month") tokenBalance.count += parseFloat(token.amount * realToken.netRentMonthPerToken);
														if (selectedRentType === "Year") tokenBalance.count += parseFloat(token.amount * realToken.netRentYearPerToken);
														tokenBalance.rent += parseFloat(token.amount * realToken.netRentYearPerToken);
														tokenBalance.total += parseFloat(token.amount * realToken.tokenPrice);
														tokenBalance.yield = parseFloat(token.rent / tokenBalance.total);
														let existingTokenIndex = tokenBalance.tokens.findIndex((t) => t.id === realToken.id);
														if (existingTokenIndex >= 0) {
															tokenBalance.tokens[existingTokenIndex].amount += parseFloat(token.amount);
															tokenBalance.tokens[existingTokenIndex].chain.push({
																id: "xdai",
																amount: parseFloat(token.amount),
																wallet: wallet,
															});
														} else {
															// If the token doesn't exist, push a new entry
															tokenBalance.tokens.push({
																id: realToken.id,
																token: realToken,
																amount: parseFloat(token.amount),
																chain: [{ id: "xdai", amount: parseFloat(token.amount), wallet: wallet }],
															});
														}
													}
												});
										}
									}

									if (tokenBalance.chain === "rmm" || tokenBalance.chain === "total") {
										if (chain === "rmm" && rmmChain) {
											const tokensList = balanceWallets[wallet].tokens[chain].tokens;
											if (tokensList)
												tokensList.forEach((token) => {
													const rmmBalance = parseInt(token.currentATokenBalance) / 10 ** token.reserve.decimals;
													if (token.reserve.underlyingAsset !== "0xe91d153e0b41518a2ce8dd3d7944fa863463a97d") {
														const realToken = tokens.filter((t) => t.gnosisContract === token.reserve.underlyingAsset)[0];
														if (realToken) {
															if (selectedRentType === "Day") tokenBalance.count += parseFloat(rmmBalance * realToken.netRentDayPerToken);
															if (selectedRentType === "Week") tokenBalance.count += parseFloat(rmmBalance * realToken.netRentDayPerToken * 7);
															if (selectedRentType === "Month") tokenBalance.count += parseFloat(rmmBalance * realToken.netRentMonthPerToken);
															if (selectedRentType === "Year") tokenBalance.count += parseFloat(rmmBalance * realToken.netRentYearPerToken);
															tokenBalance.rent += parseFloat(rmmBalance * realToken.netRentYearPerToken);
															tokenBalance.total += parseFloat(rmmBalance * realToken.tokenPrice);
															tokenBalance.yield = parseFloat(token.rent / tokenBalance.total);
															let existingTokenIndex = tokenBalance.tokens.findIndex((t) => t.id === realToken.id);
															if (existingTokenIndex >= 0) {
																tokenBalance.tokens[existingTokenIndex].amount += parseFloat(rmmBalance);
																tokenBalance.tokens[existingTokenIndex].chain.push({
																	id: "rmm",
																	amount: parseFloat(rmmBalance),
																	wallet: wallet,
																});
															} else {
																// If the token doesn't exist, push a new entry
																tokenBalance.tokens.push({
																	id: realToken.id,
																	token: realToken,
																	amount: parseFloat(rmmBalance),
																	chain: [{ id: "rmm", amount: parseFloat(rmmBalance), wallet: wallet }],
																});
															}
														}
													}
												});
										}
									}

									if (tokenBalance.chain === "rmmv3" || tokenBalance.chain === "total") {
										if (chain === "rmmv3" && rmmv3Chain) {
											const tokensList = balanceWallets[wallet].tokens[chain].tokens;
											if (tokensList)
												tokensList.forEach((token) => {
													const rmmBalance = parseInt(token.amount) / 10 ** token.token.decimals;
													const realToken = tokens.filter((t) => t.gnosisContract === token.token.id.toLowerCase())[0];
													if (realToken) {
														if (selectedRentType === "Day") tokenBalance.count += parseFloat(rmmBalance * realToken.netRentDayPerToken);
														if (selectedRentType === "Week") tokenBalance.count += parseFloat(rmmBalance * realToken.netRentDayPerToken * 7);
														if (selectedRentType === "Month") tokenBalance.count += parseFloat(rmmBalance * realToken.netRentMonthPerToken);
														if (selectedRentType === "Year") tokenBalance.count += parseFloat(rmmBalance * realToken.netRentYearPerToken);
														tokenBalance.rent += parseFloat(rmmBalance * realToken.netRentYearPerToken);
														tokenBalance.total += parseFloat(rmmBalance * realToken.tokenPrice);
														tokenBalance.yield = parseFloat(token.rent / tokenBalance.total);
														let existingTokenIndex = tokenBalance.tokens.findIndex((t) => t.id === realToken.id);
														if (existingTokenIndex >= 0) {
															tokenBalance.tokens[existingTokenIndex].amount += parseFloat(rmmBalance);
															tokenBalance.tokens[existingTokenIndex].chain.push({
																id: "rmmv3",
																amount: parseFloat(rmmBalance),
																wallet: wallet,
															});
														} else {
															// If the token doesn't exist, push a new entry
															tokenBalance.tokens.push({
																id: realToken.id,
																token: realToken,
																amount: parseFloat(rmmBalance),
																chain: [{ id: "rmmv3", amount: parseFloat(rmmBalance), wallet: wallet }],
															});
														}
													}
												});
										}
									}

									if (tokenBalance.chain === "pool" || tokenBalance.chain === "total") {
										if (chain === "pool" && poolChain) {
											const tokensList = balanceWallets[wallet].tokens[chain].tokens;
											if (tokensList)
												tokensList.forEach((token) => {
													const liquidityTokenBalance = parseFloat(token.liquidityTokenBalance);
													const totalSupply = parseFloat(token.pair.totalSupply);
													if (tokens.filter((t) => t.gnosisContract === token.pair.token0.id)[0]) {
														const realToken = tokens.filter((t) => t.gnosisContract === token.pair.token0.id)[0];
														const poolBalance = (liquidityTokenBalance * token.pair.token0.liquidity) / totalSupply;
														if (realToken) {
															const realtNbToken = realToken.pool.nbTokenRealt * realToken.pool.realtRatio;
															const holderNbToken = realToken.pool.nbTokenRealt * realToken.pool.holderRatio;
															const bonusToken = (realtNbToken * poolBalance) / holderNbToken;
															if (selectedRentType === "Day")
																tokenBalance.count += parseFloat((poolBalance + bonusToken) * realToken.netRentDayPerToken);
															if (selectedRentType === "Week")
																tokenBalance.count += parseFloat((poolBalance + bonusToken) * realToken.netRentDayPerToken * 7);
															if (selectedRentType === "Month")
																tokenBalance.count += parseFloat((poolBalance + bonusToken) * realToken.netRentMonthPerToken);
															if (selectedRentType === "Year")
																tokenBalance.count += parseFloat((poolBalance + bonusToken) * realToken.netRentYearPerToken);
															tokenBalance.rent += parseFloat((poolBalance + bonusToken) * realToken.netRentYearPerToken);
															tokenBalance.total += parseFloat(poolBalance * realToken.tokenPrice * 2);
															tokenBalance.yield = parseFloat(token.rent / tokenBalance.total);
															let existingTokenIndex = tokenBalance.tokens.findIndex((t) => t.id === realToken.id);
															if (existingTokenIndex >= 0) {
																tokenBalance.tokens[existingTokenIndex].amount += parseFloat(poolBalance);
																tokenBalance.tokens[existingTokenIndex].chain.push({
																	id: "pool",
																	amount: parseFloat(poolBalance),
																	wallet: wallet,
																});
															} else {
																// If the token doesn't exist, push a new entry
																tokenBalance.tokens.push({
																	id: realToken.id,
																	token: realToken,
																	amount: parseFloat(poolBalance),
																	chain: [{ id: "pool", amount: parseFloat(poolBalance), wallet: wallet }],
																});
															}
														}
													}
													if (tokens.filter((t) => t.gnosisContract === token.pair.token1.id)[0]) {
														const realToken = tokens.filter((t) => t.gnosisContract === token.pair.token1.id)[0];
														const poolBalance = (liquidityTokenBalance * token.pair.token1.liquidity) / totalSupply;
														if (realToken) {
															const realtNbToken = realToken.pool.nbTokenRealt * realToken.pool.realtRatio;
															const holderNbToken = realToken.pool.nbTokenRealt * realToken.pool.holderRatio;
															const bonusToken = (realtNbToken * poolBalance) / holderNbToken;
															if (selectedRentType === "Day")
																tokenBalance.count += parseFloat((poolBalance + bonusToken) * realToken.netRentDayPerToken);
															if (selectedRentType === "Week")
																tokenBalance.count += parseFloat((poolBalance + bonusToken) * realToken.netRentDayPerToken * 7);
															if (selectedRentType === "Month")
																tokenBalance.count += parseFloat((poolBalance + bonusToken) * realToken.netRentMonthPerToken);
															if (selectedRentType === "Year")
																tokenBalance.count += parseFloat((poolBalance + bonusToken) * realToken.netRentYearPerToken);
															tokenBalance.rent += parseFloat((poolBalance + bonusToken) * realToken.netRentYearPerToken);
															tokenBalance.total += parseFloat(poolBalance * realToken.tokenPrice * 2);
															tokenBalance.yield = parseFloat(token.rent / tokenBalance.total);
															let existingTokenIndex = tokenBalance.tokens.findIndex((t) => t.id === realToken.id);
															if (existingTokenIndex >= 0) {
																// console.log("Update pool", realToken.fullName, tokenBalance.tokens[existingTokenIndex].amount, poolBalance);
																tokenBalance.tokens[existingTokenIndex].amount += parseFloat(poolBalance);
																tokenBalance.tokens[existingTokenIndex].chain.push({
																	id: "pool",
																	amount: parseFloat(poolBalance),
																	wallet: wallet,
																});
															} else {
																// If the token doesn't exist, push a new entry
																tokenBalance.tokens.push({
																	id: realToken.id,
																	token: realToken,
																	amount: parseFloat(poolBalance),
																	chain: [{ id: "pool", amount: parseFloat(poolBalance), wallet: wallet }],
																});
															}
														}
													}
												});
										}
									}
								}
							}
						}
					});
		});
		setTokensBalances(tokensBalance);


		// Extraction des points GPS (name, lat, lng)
		const extractedPoints = tokens.map((token) => ({
			id: token.id,
			name: token.shortName,
			position: [parseFloat(token.coordinate.lat), parseFloat(token.coordinate.lng)], // Conversion des coordonnées
			token: token,
		}));

		extractedPoints.sort((a, b) => {
			const aa = a.token.initialLaunchDate;
			const bb = b.token.initialLaunchDate;
			return new Date(aa.date) - new Date(bb.date);
		});

		// Mettre à jour l'état avec les points extraits
		setPoints(extractedPoints);

		// Centrer la carte sur le premier point s'il existe
		if (extractedPoints.length > 0) setMapCenter(extractedPoints[0].position);
	}, [realTokens, defaultPropertyType]);

	console.table(points);

	// const setChips() =>{
	// 	tokenBalance.tokens.findIndex((t) => t.id === realToken.id);

	// }

	// if(!tokensBalances) return <></>

	return (
		<Box>
			{settingsLoaded && points && (
				<>
					<Box
						sx={{
							p: 1,
							display: "flex",
							flexDirection: "column",
							alignItems: "center",
							justifyContent: "center",
							height: "calc(100vh - 65px)", // Hauteur totale moins 46 pixels du bandeau
							width: "100%", // Prend toute la largeur de la fenêtre
						}}
					>
						<MapContainer center={mapCenter} zoom={5} style={{ height: "100%", width: "100%" }}>
							{/* TileLayer pour afficher la carte */}
							<TileLayer
								url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
								attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
							/>

							{/* Affichage des points sur la carte */}
							{points.map((point) => (
								<Marker key={point.id} position={point.position} icon={customIcon}>
									<Popup>{point.name}</Popup>
								</Marker>
							))}
						</MapContainer>
					</Box>
				</>
			)}
		</Box>
	);
}

export default WalletsMapView;
