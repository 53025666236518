import React, { useContext } from "react";
// import { MyThemeContext } from "../../context/theme-context";
import { SettingsContext } from "../../context/settings-context";
// import { SnackbarContext } from "../../context/customsnackbar-context";
import { ApiCoinGeckoContext } from "../../context/apiCoinGecko-context";
// import { Link } from "react-router-dom";

import { Box, Typography, Tooltip, Avatar } from "@mui/material";
import { TableRow, TableCell, IconButton, CircularProgress } from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";
// import Settings from "@mui/icons-material/Settings";
import FormatedPrice from "../Common/Formater/FormatedPrice";

function WalletsMenuRow({ coin }) {
	const { blockchainsClient, settingsWallet, settingsApiCoinGecko, balanceWallets } = useContext(SettingsContext);
	const { refreshCoinsBalance } = useContext(ApiCoinGeckoContext);
	// const { openSnackbar } = useContext(SnackbarContext);

	const handleRefreshCoin = (coin) => {
		refreshCoinsBalance(coin);
	};

	if (!blockchainsClient) return <></>;
	if (!settingsWallet) return <></>;
	if (!balanceWallets) return <></>;
	if (settingsWallet.selectedWallet === "") return <></>;
	if (!balanceWallets[settingsWallet.selectedWallet]) return <></>;
	if (!balanceWallets[settingsWallet.selectedWallet].coins) return <></>;

	const coinBalance = settingsWallet.selectedWallet
		? balanceWallets[settingsWallet.selectedWallet].coins[settingsWallet.selectedBlockchain][coin.name]
		: null;
	if (!coinBalance) return <></>;

	if (coinBalance.balance === 0 && settingsWallet.selectedWalletHideCoins) return <></>;

	// console.log("coin", coin.name,  coinBalance);

	const enterDelay_tooltip = 250;
	const enterRefreshDelay_tooltip = 1500;

	if (!coin.timestamp) return <></>;
	return (
		<TableRow key={coin.id} sx={{ "&:last-child td": { borderBottom: 0 }, p: 0, width: "270px" }}>
			<Tooltip sx={{ padding: 0 }} title={coin.name} enterDelay={enterDelay_tooltip}>
				<TableCell sx={{ p: 0.5, pl: 2, width: "30px" }}>
					<Avatar alt={coin.name} src={coin.imgToken} fontSize='small' sx={{ width: 20, height: 20, mx: 0 }} />
				</TableCell>
			</Tooltip>

			{coinBalance.balance > 0 && (
				<TableCell align='right' sx={{ p: 0, width: "100px" }}>
					<Box sx={{ display: "flex", justifyContent: "end", alignItems: "center" }}>
						<Tooltip title={coinBalance.balance} enterDelay={enterDelay_tooltip}>
							<Typography variant='body2' sx={{ marginLeft: "auto" }}>
								{coinBalance.balance > 0 ? coinBalance.balance.toFixed(2) : ""}
							</Typography>
						</Tooltip>
						{(coinBalance.toRefresh || coinBalance.isError) && (
							<CircularProgress sx={{ p: 0, ml: 1 }} size='1rem' color={coinBalance.isError ? "error" : "primary"} />
						)}
					</Box>
				</TableCell>
			)}

			<TableCell align='right' colSpan={coinBalance.balance > 0 ? 1 : 2} sx={{ p: 0, width: coin.balance > 0 ? "100px" : "100px" }}>
				<Box sx={{ display: "flex", justifyContent: "end", alignItems: "center" }}>
					<Tooltip
						title={coinBalance.balance > 0 ? "1 " + coin.name + " = " + parseFloat(coin.toUSD).toFixed(2) + " $" : ""}
						enterDelay={enterDelay_tooltip}
					>
						{coinBalance.balance > 0 && <FormatedPrice value={coinBalance.balance * coinBalance.usd} />}
						{!coinBalance.balance > 0 && (
							<Typography sx={{ marginLeft: "auto", fontWeight: "light", fontStyle: "italic" }} color='secondary' variant='body2'>
								{"1 " + coin.name + " = " + parseFloat(coin.toUSD).toFixed(2) + " $"}
							</Typography>
						)}
					</Tooltip>

					{/* )} */}
					{(coin.toRefresh || coin.isError) && <CircularProgress sx={{ p: 0, ml: 1 }} size='1rem' color={coin.isError ? "error" : "primary"} />}
				</Box>
			</TableCell>

			<TableCell sx={{ p: 0.5, pl: 3, width: "50px" }}>
				<Tooltip sx={{ padding: 0 }} title={"Refresh " + coin.name} enterDelay={enterRefreshDelay_tooltip}>
					<IconButton onClick={() => handleRefreshCoin(coin.name)} sx={{ m: 0, p: 0 }}>
						<RefreshIcon />
					</IconButton>
				</Tooltip>
			</TableCell>
		</TableRow>
	);
}

export default WalletsMenuRow;
