import React, { useContext, useEffect, useRef, useState } from "react";
import { SettingsContext } from "../../context/settings-context";
import { MyThemeContext } from "../../context/theme-context";

import { green, red } from "@mui/material/colors";

import {
	Box,
	Paper,
	Popper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Tooltip,
	Typography,
	useMediaQuery,
} from "@mui/material";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import StraightIcon from "@mui/icons-material/Straight";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import AnnouncementIcon from "@mui/icons-material/Announcement";
import NotificationsIcon from "@mui/icons-material/Notifications";

import FormatedNumber from "../Common/Formater/FormatedNumber";
import FormatedPrice from "../Common/Formater/FormatedPrice";
import FormatedPercentage from "../Common/Formater/FormatedPercentage";
import FormatedNumberDigit from "../Common/Formater/FormatedNumberDigit";
// import TokenInfos from "../../../Common/TokenInfos";
// import TokenNews from "../../../Common/TokenNews";

function TokensTableBody({ token, chains }) {
	const { DEFAULT_PROPERTY_TYPE } = useContext(SettingsContext);
	const { themeContext } = useContext(MyThemeContext);
	const [openAmount, setOpenAmount] = useState(false);
	const [timerId, setTimerId] = useState(null);
	const [openInfo, setOpenInfo] = useState(false);
	const [openNews, setOpenNews] = useState(false);
	const anchorRefAmount = useRef(null);

	const isMatch = useMediaQuery(themeContext.breakpoints.down("lg"));

	const handleTokenClick = (marketplaceLink) => {
		window.open(marketplaceLink, "_blank");
	};
	const handleMouseEnterAmount = () => {
		const id = setTimeout(() => {
			setOpenAmount(true);
		}, 1000);
		setTimerId(id);
	};
	const handleMouseLeaveAmount = () => {
		if (timerId) {
			clearTimeout(timerId);
			setTimerId(null);
		}
		setOpenAmount(false);
	};
	useEffect(() => {
		return () => {
			if (timerId) {
				clearTimeout(timerId);
			}
		};
	}, [timerId]);

	if (!token) return <></>;

	let annualYield = null;
	let rentedUnits = null;
	// let annualYield = null;
	if (Array.isArray(token.update7)) {
		// console.log("7", token.shortName, token.update7);
		token.update7.forEach((t) => {
			if (t.key === "annualPercentageYield") {
				annualYield = t;
			}
			if (t.key === "rentedUnits") {
				rentedUnits = t;
			}
			// console.log("7", token.shortName, t);
		});
	} else {
		console.warn("token.update7 is not an array");
	}
	if (!annualYield)
		if (Array.isArray(token.update30)) {
			token.update30.forEach((t) => {
				if (t.key === "annualPercentageYield") {
					annualYield = t;
				}
				if (t.key === "rentedUnits") {
					rentedUnits = t;
				}
				// console.log("30", token.shortName, t);
			});
		} else {
			console.warn("token.update30 is not an array");
		}
	// if (annualYield) {
	// 	console.log("new_value:", annualYield.new_value, "parsed:", parseFloat(annualYield.new_value));
	// 	console.log("old_value:", annualYield.old_value, "parsed:", parseFloat(annualYield.old_value));
	// }

	return (
		<>
			{/* <TokenInfos open={openInfo} setOpen={setOpenInfo} token={token} />
			<TokenNews open={openNews} setOpen={setOpenNews} token={token} chains={chains} /> */}
			<TableRow key={token.id} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
				<TableCell
					align='center'
					sx={{ pl: 1, pr: 1, py: 1, "&:hover": { cursor: "pointer" } }}
					component='th'
					scope='row'
					// onClick={() => handleTokenClick(token.marketplaceLink)}
					onClick={() => handleTokenClick(token.marketplaceLink)}
				>
					<Box sx={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
						<OpenInNewIcon color={"primary"} />
					</Box>
				</TableCell>

				<TableCell align='center' sx={{ pl: 1, pr: 1, py: 1 }}>
					<Tooltip title={token.fullName}>
						<Typography variant='body2' onClick={() => handleTokenClick(token.marketplaceLink)}>
							{token.shortName}
						</Typography>
					</Tooltip>
				</TableCell>

				<TableCell align='center' sx={{ pl: 1, pr: 1, py: 1 }}>
					<FormatedPrice value={token.initPrice} color={"primary.main"} />
				</TableCell>

				<TableCell align='center' sx={{ pl: 1, pr: 1, py: 1 }}>
					<FormatedPrice value={token.tokenPrice} color={"secondary.main"} />
				</TableCell>

				<TableCell align='center' sx={{ pl: 1, pr: 1, py: 1 }}>
					<FormatedPercentage value={token.initYield / 100} color={"primary.main"} />
				</TableCell>

				<TableCell align='center' sx={{ pl: 1, pr: 1, py: 1 }}>
					<Box sx={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
						{annualYield && (
							<Tooltip title={`from ${parseFloat(annualYield.old_value).toFixed(2)}% to ${parseFloat(annualYield.new_value).toFixed(2)}%`}>
								{parseFloat(annualYield.new_value) > parseFloat(annualYield.old_value) ? (
									<ArrowUpwardIcon sx={{ color: green[500] }} />
								) : (
									<ArrowDownwardIcon sx={{ color: red[500] }} />
								)}
							</Tooltip>
						)}
						<FormatedPercentage value={token.annualPercentageYield / 100} color={"secondary.main"} />
					</Box>
				</TableCell>

				<TableCell align='center' sx={{ pl: 1, pr: 1, py: 1 }}>
					<Box sx={{ display: "flex", justifyContent: "center" }}>
						{rentedUnits && (
							<Tooltip
								title={`from ${parseFloat(rentedUnits.old_value).toFixed(0)} to ${parseFloat(rentedUnits.new_value).toFixed(0)} unit(s) rented`}
							>
								{parseFloat(rentedUnits.new_value) > parseFloat(rentedUnits.old_value) ? (
									<ArrowUpwardIcon sx={{ color: green[500] }} />
								) : (
									<ArrowDownwardIcon sx={{ color: red[500] }} />
								)}
							</Tooltip>
						)}
						<FormatedNumberDigit value={token.rentedUnits} color={"primary.main"} digit={0} />/
						<FormatedNumber value={token.totalUnits} color={"primary.main"} />
					</Box>
				</TableCell>

				<TableCell align='center' sx={{ pl: 1, pr: 1, py: 1 }}>
					<FormatedPercentage value={token.rentedUnits / token.totalUnits} color={"secondary.main"} />
				</TableCell>
				<TableCell align='center' sx={{ pl: 1, pr: 1, py: 1 }}>
					<Typography variant='body2'>{token.strRentStartDate}</Typography>
				</TableCell>
			</TableRow>
		</>
	);
}

export default TokensTableBody;
