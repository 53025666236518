import React, { useContext, useEffect, useState } from "react";
import { SettingsContext } from "../context/settings-context";
// import { SnackbarContext } from "../context/customsnackbar-context";
// import { useNavigate, useLocation } from "react-router-dom";
import { useLocation } from "react-router-dom";

import { Box } from "@mui/material";

import Header from "../components/Header/Header";
import LoadingWebSite from "../components/Common/LoadingWebSite";
import TableTokens from "../components/Upmp/TableTokens";

const TITLE = "Pit's BI - Upmp";

function UPMP() {
	const { settingsLoaded } = useContext(SettingsContext);
	// const { openSnackbar } = useContext(SnackbarContext);
	const location = useLocation();
	// const navigate = useNavigate();
	
  useEffect(() => {
    document.title = TITLE;
 }, []);


	// const { openSnackbar } = useContext(SnackbarContext);
	return (
		<Box>
			{/* <Header /> */}

			{/* <button onClick={() => openSnackbar("Message de test", "success")}>Ouvrir Snackbar</button> */}

			{!settingsLoaded && <LoadingWebSite />}
			{settingsLoaded && (
				<Box>
					<Box sx={{ p: 0, display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
						<TableTokens />
					</Box>
				</Box>
			)}
		</Box>
	);
}

export default UPMP;
