import React, { useContext, useState, useEffect } from "react";
import { ThemeProvider } from "@mui/material/styles";
import { MyThemeContext } from "./context/theme-context";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import i18n from "./i18n";
import { useTranslation, initReactI18next } from "react-i18next";

import CssBaseline from "@mui/material/CssBaseline";
import About from "./pages/About";
import Dashboard from "./pages/Dashboard";
import Help from "./pages/Help";
import Map from "./pages/Map";
import Market from "./pages/Market";
import Settings from "./pages/Settings";
import Stats from "./pages/Stats";
import Traffic from "./pages/Traffic";

import DonatePooper from "./components/Common/DonatePooper";
import AdPopupReferer from "./components/Common/AdPopupReferer";
import UPMP from "./pages/UPMP";

i18n
	.use(initReactI18next) // Intégration avec React
	.init({
		resources: {
			en: {
				translation: {
					welcome: "Welcome to our website",
					// Ajoutez d'autres traductions ici
				},
			},
			fr: {
				translation: {
					welcome: "Bienvenue sur notre site",
					// Ajoutez d'autres traductions ici
				},
			},
		},
		lng: "en", // Forcer l'anglais comme langue par défaut
		fallbackLng: "en", // Utiliser l'anglais si une traduction n'existe pas
		interpolation: {
			escapeValue: false, // React se charge déjà de la protection contre XSS
		},
		detection: {
			order: ["cookie", "localStorage"],
			caches: ["cookie", "localStorage"],
		},
	});

const App = () => {
	const { themeContext } = useContext(MyThemeContext);
	const { t } = useTranslation();

	// Utilisez useEffect pour forcer la langue à l'anglais lors du montage du composant
	useEffect(() => {
		i18n.changeLanguage("en"); // Force la langue à l'anglais
	}, []);

	return (
		<>
			<ThemeProvider theme={themeContext}>
				<CssBaseline />
				<BrowserRouter>
					<PopperWrapper>
						{/* Composant de popup publicitaire */}
						<AdPopupReferer />

						<Routes>
							<Route path='/about' element={<About />} />
							<Route path='/dashboard' element={<Dashboard />} />
							<Route path='/help' element={<Help />} />
							<Route path='/map' element={<Map />} />
							<Route path='/market' element={<Market />} />
							<Route path='/settings' element={<Settings />} />
							<Route path='/stats' element={<Stats />} />
							<Route path='/traffic' element={<Traffic />} />
							<Route path='/upmp' element={<UPMP />} />
							<Route path='*' element={<Navigate to='/stats' />} />
						</Routes>
					</PopperWrapper>
				</BrowserRouter>
			</ThemeProvider>
		</>
	);
};

function PopperWrapper({ children }) {
	const location = useLocation();

	// List of paths where popper should not be displayed
	const excludedPaths = ["/about", "/help", "/settings", "/upmp"];

	// Determine if the current path is excluded
	const isExcluded = excludedPaths.includes(location.pathname);

	// État pour gérer l'ouverture et l'ancre du popper
	const [anchorEl, setAnchorEl] = useState(null);
	const [open, setOpen] = useState(false);

	// Utilisation de useEffect pour gérer l'affichage du popper toutes les 24 heures
	useEffect(() => {
		const lastOpened = localStorage.getItem("pitsbi__realt__lastOpenedPopper");
		const now = new Date().getTime();

		// console.log("pitsbi__realt__lastOpenedPopper", now - lastOpened, lastOpened - now, lastOpened > now);

		if (!lastOpened || now - lastOpened > 24 * 60 * 60 * 1000 || lastOpened - now < -24 * 60 * 60 * 1000 || lastOpened > now) {
			setAnchorEl(document.body); // ou l'élément sur lequel vous voulez ancrer le popper
			setOpen(true);
			localStorage.setItem("pitsbi__realt__lastOpenedPopper", now);
		}
	}, [location.pathname]); // Réévaluer l'effet lorsque le chemin change

	// Fermer le popper après un certain temps ou quand l'utilisateur clique ailleurs
	useEffect(() => {
		if (open) {
			const timer = setTimeout(() => {
				setOpen(false);
				setAnchorEl(null);
			}, 7500); // Par exemple, fermer après 5 secondes
			return () => clearTimeout(timer);
		}
	}, [open]);

	return (
		<>
			{!isExcluded && <DonatePooper open={open} anchorEl={anchorEl} setAnchorEl={setAnchorEl} />}
			{children}
		</>
	);
}

export default App;
