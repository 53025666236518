import React, { useContext, useEffect, useState } from "react";
import { SettingsContext } from "../../context/settings-context";

import { Box, Typography, Button,Select,MenuItem } from "@mui/material";
import WalletsMapView from "./WalletsMapView";
import VisitorsMapView from "./VisitorsMapView";

const TITLE = "Pit's BI - Map";

function MenuMapView({ defaultPropertyType = 0 }) {
	
	const { DEFAULT_PROPERTY_TYPE } = useContext(SettingsContext);
	const { settingsDashboard,setSettingsDashboard, balanceWallets, holderWallets } = useContext(SettingsContext);
	const [showLegend, setShowLegend] = useState(false);
	const [showNotFullRented, setShowNotFullRented] = useState(false);

	useEffect(() => {
		document.title = TITLE;
	}, []);

	

	const selectedPropertyTypeChange = (newValue) => {
		setSettingsDashboard((prevSettings) => ({
			...prevSettings,
			selectedPropertyType: newValue,
		}));
	};
	
	// useEffect(() => {
	// 	if(!settingsDashboard.selectedPropertyType) return;
	// }, []);

	return (
		<>
			<Box
			>
				<Box //Box pour faire un bandeau de commande en haut de la MAP
					sx={{
						p: 0.5,
						height: "50px",
					display: "flex",
						justifyContent: "center",
						width: "100%", // Prend toute la largeur de la fenêtre
						border: 0,
					}}
				>
					<Typography variant='h5' sx={{ m: 1, ml: 0, color: "primary.main" }}>
					Property Type
				</Typography>
				<Select
					sx={{ mt: 0, ml: 0, minWidth: 125 }}
					value={settingsDashboard.selectedPropertyType}
					onChange={(e) => {
						selectedPropertyTypeChange(e.target.value);
					}}
					size='small'
				>
					{DEFAULT_PROPERTY_TYPE.filter((p) => !p.label.startsWith("Text_")).map((p) => (
						<MenuItem key={p.index} value={p.index}>
							{p.label}
						</MenuItem>
					))}
				</Select>

					<Button
						variant='contained'
						onClick={() => setShowLegend(!showLegend)}
						sx={{
							ml: "auto",
						}}
					>
						Légende
					</Button>
				</Box>

				<Box sx={{
					height: "calc(100vh - 114px)", // Hauteur totale moins 46 pixels du bandeau
					width: "100%", // Prend toute la largeur de la fenêtre
					border: 0,
				}}>
					<VisitorsMapView  defaultPropertyType={settingsDashboard.selectedPropertyType}/>
				</Box>
			</Box>
		</>
	);
}

export default MenuMapView;
