import React, { useContext, useEffect, useState } from "react";
import { SettingsContext } from "../../context/settings-context";
import { MyThemeContext } from "../../context/theme-context";
import { useTheme } from "@mui/system";
import Web3 from "web3";

import { Slide, Popper } from "@mui/material";

import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { green, red, blue, purple, orange } from "@mui/material/colors";
import FormatedNumberDigit from "./Formater/FormatedNumberDigit";
import Donate from "./Donate";

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction='up' ref={ref} {...props} />;
});

function DonatePopper({ open, anchorEl, setAnchorEl }) {
	// const [anchorEl, setAnchorEl] = React.useState(null);
	const [countdown, setCountdown] = useState(15);
	// const open = Boolean(anchorEl);
	const id = open ? "simple-popper" : undefined;

	const setOpen = (open) => {
		console.log("La fenetre se ferme toute seule ...");
	};

	if (!open) return <></>;

	// console.log("token", isMatch, token);

	return (
		<Popper id={id} open={open} anchorEl={anchorEl} sx={{ p: 1 }}>
			<Donate open={open} setOpen={setOpen} />
		</Popper>
	);
}

export default DonatePopper;
