import React, { useContext, useEffect, useState } from "react";
import { SettingsContext } from "../../context/settings-context";
import { MyThemeContext } from "../../context/theme-context";
import { useTheme } from "@mui/system";
import Web3 from "web3";

import {
	Box,
	Slide,
	Dialog,
	Button,
	Typography,
	useMediaQuery,
	DialogTitle,
	DialogContent,
	DialogContentText,
	DialogActions,
	Avatar,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TablePagination,
	TableRow,
} from "@mui/material";

import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { green, red, grey, blue, purple, orange } from "@mui/material/colors";
import FormatedNumberDigit from "./Formater/FormatedNumberDigit";
import FallingCoins from "./FallingCoins";

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction='up' ref={ref} {...props} />;
});

function Donate({ open, setOpen }) {
	const { blockchainsClient } = useContext(SettingsContext);

	const { themeContext } = useContext(MyThemeContext);
	const [xdai_balance, setXdai_balance] = useState(null);
	const [usdc_balance, setUsdc_balance] = useState(null);
	const [lendUSDCRMMv3_balance, setLendUSDCRMMv3_balance] = useState(null);
	const [lendWxDAIRMMv3_balance, setLendWxDAIRMMv3_balance] = useState(null);

	const isMatch = useMediaQuery(themeContext.breakpoints.down("lg"));
	const theme = useTheme();

	const bc = blockchainsClient["Gnosis"];
	const web3 = new Web3(bc.rpc);
	const wallet = "0x565980d400dc6d37e74baedaecb071f166f99352";
	const walletfp = "0xe5f43ba510d3ea0d7288ec9f10fce02a65eff4f7";

	const getCoinsBalance = async () => {
		setXdai_balance(parseFloat(parseInt(await web3.eth.getBalance(wallet)) / 10 ** 18));

		const contract0 = new web3.eth.Contract(bc.coinList["USDC"].abi, bc.coinList["USDC"].address.toLowerCase());
		let res = parseInt(await contract0.methods.balanceOf(wallet).call());
		setUsdc_balance(parseFloat(res / 10 ** bc.coinList["USDC"].decimal));
		// console.log("res", res, usdc_balance);

		const contract1 = new web3.eth.Contract(bc.coinList["lendWxDAIRMMv3"].abi, bc.coinList["lendWxDAIRMMv3"].address.toLowerCase());
		res = parseInt(await contract1.methods.balanceOf(wallet).call());
		setLendWxDAIRMMv3_balance(parseFloat(res / 10 ** bc.coinList["lendWxDAIRMMv3"].decimal));

		const contract2 = new web3.eth.Contract(bc.coinList["lendUSDCRMMv3"].abi, bc.coinList["lendUSDCRMMv3"].address.toLowerCase());
		res = parseInt(await contract2.methods.balanceOf(wallet).call());
		setLendUSDCRMMv3_balance(parseFloat(res / 10 ** bc.coinList["lendUSDCRMMv3"].decimal));
	};

	const getRegPoolPrice = async () => {
		const poolAddress = "0x7c65b137d0ac0ab0cff2afa690a12575afbd125c"; // L'adresse de la pool Uniswap V2
		const poolABI = [
			{
				constant: true,
				inputs: [],
				name: "getReserves",
				outputs: [
					{ name: "_reserve0", type: "uint112" },
					{ name: "_reserve1", type: "uint112" },
					{ name: "_blockTimestampLast", type: "uint32" },
				],
				payable: false,
				stateMutability: "view",
				type: "function",
			},
			{
				constant: true,
				inputs: [{ name: "owner", type: "address" }],
				name: "balanceOf",
				outputs: [{ name: "balance", type: "uint256" }],
				payable: false,
				stateMutability: "view",
				type: "function",
			},
			{
				constant: true,
				inputs: [],
				name: "totalSupply",
				outputs: [{ name: "", type: "uint256" }],
				payable: false,
				stateMutability: "view",
				type: "function",
			},
		];

		const poolContract = new web3.eth.Contract(poolABI, poolAddress);

		async function getTokenPrices() {
			try {
				const reserves = await poolContract.methods.getReserves().call();
				const lpBalanceContract = await poolContract.methods.balanceOf(walletfp).call();
				const totalSupplyContract = await poolContract.methods.totalSupply().call();

				const lpBalance = Number(lpBalanceContract) / 10 ** 18;
				const totalSupply = Number(totalSupplyContract) / 10 ** 18;

				console.log("reserves:", reserves);
				console.log("lpBalance:", lpBalance);
				console.log("totalSupply:", totalSupply);

				const yourShare = lpBalance / totalSupply;
				console.log(`Your share of the pool: ${yourShare}`);

				const reserve0 = Number(reserves._reserve0) / 10 ** 18;
				const reserve1 = Number(reserves._reserve1) / 10 ** 18;
				console.log("reserve0:", reserve0);
				console.log("reserve1:", reserve1);

				const priceToken1InToken0 = reserve0 / reserve1;
				const priceToken0InToken1 = reserve1 / reserve0;

				console.log(`Price of Token1 in terms of Token0: ${priceToken1InToken0}`);
				console.log(`Price of Token0 in terms of Token1: ${priceToken0InToken1}`);

				console.log(`Price of my Token1 in terms of Token0: ${priceToken1InToken0 * lpBalance} ${yourShare * reserve0}`);
				console.log(`Price of my Token0 in terms of Token1: ${priceToken0InToken1 * lpBalance} ${yourShare * reserve1}`);
			} catch (error) {
				console.error("Error fetching reserves:", error);
			}
		}

		getTokenPrices();
	};

	useEffect(() => {
		getCoinsBalance();

		// getRegPoolPrice();
	}, [open]);

	const handleClose = () => {
		setOpen(false);
	};

	if (!open) return <></>;

	// console.log("token", isMatch, token);

	const size_token_to_paid = 25;
	const pitsrmm_sizeW = 70;
	const pitsrmm_sizeH = 58;
	const imgPitsRMM = "/images/pits-RMM.png";

	return (
		<Dialog
			open={open}
			TransitionComponent={Transition}
			keepMounted
			onClose={handleClose}
			sx={{
				m: 0,
				width: 1,
				"& .MuiDialog-paper": {
					width: isMatch ? 800 : 1100,
					maxWidth: "none",
					border: 5,
					color: blue[600],
					borderRadius: 0,
				},
			}}
		>
			<DialogTitle sx={{ mt: 1, mx: 1, p: 0, border: 0 }}>
				<Box sx={{ ml: 0, display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center" }}>
					<Avatar
						alt='Logo'
						src={imgPitsRMM}
						sx={{ p: 0, ml: 2, width: pitsrmm_sizeW, height: pitsrmm_sizeH, border: 0, borderColor: grey[500], borderRadius: 0 }}
					/>
					<Typography variant='h4' sx={{ m: 0, width: "100%", textAlign: "center", color: blue[600] }}>
						<strong>Support the Pit'sBI Community Fund for RealT</strong>
					</Typography>
					<Avatar
						alt='Logo'
						src={imgPitsRMM}
						sx={{ p: 0, mr: 2, width: pitsrmm_sizeW, height: pitsrmm_sizeH, border: 0, borderColor: grey[500], borderRadius: 0 }}
					/>
				</Box>
				<Typography variant='h6' sx={{ m: 0, width: "100%", textAlign: "center", color: blue[600], fontStyle: "italic" }}>
					Join me in building a <strong>community-driven fund</strong> on the RMM platform of RealT.
				</Typography>
				<Typography variant='h6' sx={{ m: 0, width: "100%", textAlign: "center", color: blue[600], fontStyle: "italic" }}>
					Your contributions, in the form of Tokens or fractions of realTokens, will be directly deposited into a fund on the RMM Protocol, <br />
					which is a lending and borrowing system.
				</Typography>
				<Typography variant='h6' sx={{ m: 0, width: "100%", textAlign: "center", color: blue[600], fontStyle: "italic" }}>
					This system allows property shares to be used as collateral to borrow up to 60% of their value, <br />
					enabling reinvestment in additional shares or other opportunities.
				</Typography>
				{/* <FallingCoins /> */}
			</DialogTitle>
			<DialogContent sx={{ m: 1, mx: 1, p: 0, border: 0 }}>
				<DialogContentText id='alert-dialog-slide-description' sx={{ mt: 4, mb: 5, mx: 15, p: 2, border: 4, color: blue[500] }}>
					<Typography variant='h5' sx={{ m: 0, width: "100%", textAlign: "center", color: blue[600] }}>
						<strong>Wallet Address of the Fund</strong>
					</Typography>
					<Typography variant='h5' sx={{ m: 0, width: "100%", textAlign: "center", color: blue[600] }}>
						{wallet}
					</Typography>
				</DialogContentText>

				<DialogContentText
					id='alert-dialog-slide-description'
					sx={{ mx: 0, mb: 5, border: 0, color: green[500], display: "flex", alignItems: "center", justifyContent: "center" }}
				>
					<Box sx={{ ml: 0, p: 2, width: 450, border: 4, color: green[500], flexDirection: "column" }}>
						<Typography variant='h5' sx={{ m: 0, width: "100%", textAlign: "left", color: blue[600] }}>
							<strong>Current Contributions:</strong>
						</Typography>
						<Box sx={{ ml: 0, display: "flex", alignItems: "center", justifyContent: "center" }}>
							<Typography variant='h5' sx={{ m: 0, mr: 2, width: 200, border: 0, color: blue[600] }}>
								{bc.coinList["xDAI"].name}
							</Typography>
							<FormatedNumberDigit variant='h5' value={xdai_balance} color={blue[600]} digit={2} />
							<Box
								component='img'
								alt='imgToken'
								src={bc.coinList["xDAI"].imgToken}
								sx={{
									ml: 1,
									my: 0,
									p: 0,
									width: size_token_to_paid,
									height: size_token_to_paid,
									display: "flex",
									alignItems: "center",
									justifyContent: "center",
								}}
							/>
						</Box>

						<Box sx={{ ml: 0, display: "flex", alignItems: "center", justifyContent: "center" }}>
							<Typography variant='h5' sx={{ m: 0, mr: 2, width: 200, border: 0, color: blue[600] }}>
								{bc.coinList["USDC"].name}
							</Typography>
							<FormatedNumberDigit variant='h5' value={usdc_balance} color={blue[600]} digit={2} />
							<Box
								component='img'
								alt='imgToken'
								src={bc.coinList["USDC"].imgToken}
								sx={{
									ml: 1,
									my: 0,
									p: 0,
									width: size_token_to_paid,
									height: size_token_to_paid,
									display: "flex",
									alignItems: "center",
									justifyContent: "center",
								}}
							/>
						</Box>

						<Box sx={{ ml: 0, display: "flex", alignItems: "center", justifyContent: "center" }}>
							<Typography variant='h5' sx={{ m: 0, mr: 2, width: 200, border: 0, color: blue[600] }}>
								{bc.coinList["lendWxDAIRMMv3"].name}
								{":"}
							</Typography>
							<FormatedNumberDigit variant='h5' value={lendWxDAIRMMv3_balance} color={blue[600]} digit={2} />
							<Box
								component='img'
								alt='imgToken'
								src={bc.coinList["lendWxDAIRMMv3"].imgToken}
								sx={{
									ml: 1,
									my: 0,
									p: 0,
									width: size_token_to_paid,
									height: size_token_to_paid,
									display: "flex",
									alignItems: "center",
									justifyContent: "center",
								}}
							/>
						</Box>

						<Box sx={{ ml: 0, display: "flex", alignItems: "center", justifyContent: "center" }}>
							<Typography variant='h5' sx={{ m: 0, mr: 2, width: 200, border: 0, color: blue[600] }}>
								{bc.coinList["lendUSDCRMMv3"].name}
								{":"}
							</Typography>
							<FormatedNumberDigit variant='h5' value={lendUSDCRMMv3_balance} color={blue[600]} digit={2} />
							<Box
								component='img'
								alt='imgToken'
								src={bc.coinList["lendUSDCRMMv3"].imgToken}
								sx={{
									ml: 1,
									my: 0,
									p: 0,
									width: size_token_to_paid,
									height: size_token_to_paid,
									display: "flex",
									alignItems: "center",
									justifyContent: "center",
								}}
							/>
						</Box>
					</Box>
				</DialogContentText>

				<DialogContentText id='alert-dialog-slide-description' sx={{ m: 1, p: 2, border: 1, color: blue[500] }}>
					<Typography variant='h6' sx={{ color: blue[600] }}>
						Why contribute to Pit's RMM ?
					</Typography>
					<Typography variant='body2' sx={{ color: blue[600], fontStyle: "italic" }}>
						By contributing to this fund, you are helping it grow, which in turn allows the community to borrow at more attractive rates. The interest
						generated from these loans will not only benefit the borrowers but also cover the operational costs of Pit'sBI. This includes the cloud
						infrastructure (API: Heroku, DB: Amazon AWS, Front End: Netlify, etc.) to ensure continuous and reliable service.
					</Typography>
					<Typography variant='body2' sx={{ color: blue[600], fontStyle: "italic" }}>
						With your help, Pit'sBI can continue to support the RealT holder community by providing valuable resources and tools. <br/>Your contribution is
						an investment in the community's future.
					</Typography>

					<Typography variant='body2' sx={{ color: blue[600], fontStyle: "italic" }}>
						<br />
						For more updates and direct communication, feel free to{" "}
						<a href='https://t.me/Fredo_realT' target='_blank'>
							join me on Telegram
						</a>
						.
					</Typography>
				</DialogContentText>
			</DialogContent>
			{/* <DialogActions sx={{ m: 1, border: 0, color: red[500] }}>
				<Box sx={{ ml: 0, width: 1, display: "flex", alignItems: "center", justifyContent: "end" }}>
					<Button onClick={handleClose}>Close</Button>
				</Box>
			</DialogActions> */}
		</Dialog>
	);
}

export default Donate;
